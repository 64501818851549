import { UseQueryOptions, useQuery } from 'react-query';

import { CustomLinksResultsDto, CustomLinksService } from 'src/Services/API';

export const useGetCustomLinksList = (config?: UseQueryOptions<CustomLinksResultsDto[], Error>) => {
  return useQuery<CustomLinksResultsDto[], Error>(
    'custom-links',
    async () => {
      const links = await CustomLinksService.customLinksControllerGetAll();
      links.sort((a, b) => {
        if (a.agencyId > b.agencyId) return 1;
        if (a.agencyId < b.agencyId) return -1;
        if (a.companyName > b.companyName) return 1;
        if (a.companyName < b.companyName) return -1;
        if (a.qualificationLabel > b.qualificationLabel) return 1;
        if (a.qualificationLabel < b.qualificationLabel) return -1;
        return 0;
      });
      return links;
    },
    {
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
