import React from 'react';

import { useAuth } from 'src/Hooks/Authentication/useAuth';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';

import HomeParametersPopOverMenu from './HomeParametersPopOverMenu.component';

const MemoHomeParametersPopOverMenu = React.memo(HomeParametersPopOverMenu);

const EnhancedHomeParametersPopOverMenu = () => {
  const { logout } = useAuth();
  const { givenName, familyName, email } = useAuthenticatedUser();

  return (
    <MemoHomeParametersPopOverMenu
      firstName={givenName}
      lastName={familyName}
      email={email}
      onLogout={() =>
        logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI } })
      }
    />
  );
};

export default EnhancedHomeParametersPopOverMenu;
