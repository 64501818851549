import React from 'react';

import { AppState, Auth0Provider as RawAuth0Provider } from '@auth0/auth0-react';

import history from 'src/Utils/history';

const onRedirectCallback = (appState?: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

export const Auth0Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <RawAuth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI ?? '',
      }}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </RawAuth0Provider>
  );
};
