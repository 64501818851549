import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { AddRightsParams, RightsService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useAddRights = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.addRights],
    async (params: AddRightsParams) => {
      return RightsService.rightsControllerAddRights({ body: params });
    },
    {
      onSuccess: () => {
        toast.success('les droits ont été accordés au nouveau collaborateur');
        queryClient.invalidateQueries(QueryKeys.fetchRights);
      },
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour associer ce profil à un collaborateur');
            break;
          case 404:
            toast.error('adresse mail du collaborateur incorrecte, veuillez la corriger');
            break;
          case 409:
            toast.error(
              'le collaborateur a déjà des droits, retrouvez-les dans la page de gestion des droits'
            );
            break;
          default:
            toast.error("erreur lors de l'attribution des droits");
            break;
        }
      },
    }
  );
};
