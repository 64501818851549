import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { Right, RightsService } from 'src/Services/API';

import { useAuth } from '../Authentication/useAuth';
import { QueryKeys } from '../types';

export const useFetchRights = (extraOptions?: UseQueryOptions<Right[], AxiosError>) => {
  const { isAuthenticated } = useAuth();

  return useQuery<Right[], AxiosError>(
    [QueryKeys.fetchRights],
    async () => {
      return await RightsService.rightsControllerGetRights();
    },
    {
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour accéder aux droits des utilisateurs');
            break;
          default:
            toast.error('erreur lors de la récupération des droits');
        }
      },
      enabled: isAuthenticated,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
