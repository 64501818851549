import { UseQueryOptions, useQuery } from 'react-query';

import { AgenciesService, ConsultantAgencies } from 'src/Services/API';

export const useGetAgencyList = (config?: UseQueryOptions<ConsultantAgencies, Error>) => {
  return useQuery<ConsultantAgencies, Error>(
    ['agency-list'],
    async () => {
      const data = await AgenciesService.agenciesControllerGetConsultantAgencies();
      data.agencies.sort();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
