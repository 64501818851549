import React from 'react';

import { Route, Router, Switch, useLocation } from 'react-router-dom';

import { LocationState } from 'src/Services/Routing';
import history from 'src/Utils/history';

import { CustomRandySection, CustomRandySubSection, URL } from '../CustomRandy.types';

import AgencyAddCalendarScreen from './AgencyAddCalendarScreen';
import AgencyConfigScreen from './AgencyConfigScreen';
import AgencyEditCalendarScreen from './AgencyEditCalendarScreen';
import AgencyListScreen from './AgencyListScreen';

const Agency = () => {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  return (
    <Router history={history}>
      <Switch location={background || location}>
        <Route
          exact
          path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}`}
          component={AgencyListScreen}
        />
        <Route
          exact
          path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/:agencyId`}
          component={AgencyConfigScreen}
        />
        <Route
          exact
          path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/:agencyId/add-calendar`}
          component={AgencyAddCalendarScreen}
        />
        <Route
          exact
          path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/:agencyId/:calendarId`}
          component={AgencyEditCalendarScreen}
        />
      </Switch>
    </Router>
  );
};

export default Agency;
