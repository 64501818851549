import React, { useEffect } from 'react';

import { Route, Router, Switch, useLocation } from 'react-router-dom';

import { LocationState, setTitleAndIcon } from 'src/Services/Routing';
import history from 'src/Utils/history';

import Agency from './Agency';
import Styles from './CustomRandy.module.scss';
import { CustomRandySection, CustomRandySubSection, ICON, URL } from './CustomRandy.types';
import Links from './Links';
import SideBar from './SideBar';

const CustomRandy = () => {
  useEffect(() => {
    setTitleAndIcon('randy personnalisé', ICON);
  });

  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  useEffect(() => {
    if (location.pathname.split('/').length === 2)
      history.replace(`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={Styles.CustomRandy}>
      <SideBar />
      <div className={Styles.ContentContainer}>
        <Router history={history}>
          <Switch location={background || location}>
            <Route
              path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}`}
              component={Links}
            />
            <Route
              path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}`}
              component={Agency}
            />
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default CustomRandy;
