import React from 'react';

import MenuItem from './MenuItem';
import Styles from './MenuSection.module.scss';
import Props from './MenuSection.types';
import MenuSectionTitle from './MenuSectionTitle';

const MenuSection = <TItem extends [string, string]>({
  title,
  item,
  items,
  opened,
  activeItem,
  setItem,
}: Props<TItem>) => {
  return (
    <div className={Styles.MenuSection}>
      <MenuSectionTitle title={title} opened={opened} />
      {items.map(child => (
        <MenuItem<TItem>
          key={child.item[1]}
          {...child}
          parent={item}
          opened={opened}
          activeItem={activeItem}
          setItem={setItem}
        />
      ))}
    </div>
  );
};

export default MenuSection;
