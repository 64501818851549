import React from 'react';

import { Auth0Provider } from './Auth/auth0';
import { ReactQueryProvider } from './ReactQuery';
import { ReduxProvider } from './Redux';

const Providers = ({ children }: { children: React.ReactElement }) => (
  <Auth0Provider>
    <ReduxProvider>
      <ReactQueryProvider>{children}</ReactQueryProvider>
    </ReduxProvider>
  </Auth0Provider>
);

export default Providers;
