import React from 'react';

import { HighlightedAppBackground } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import history from 'src/Utils/history';

import styles from './HighlightedAppButton.module.scss';
import { Props } from './HighlightedAppButton.types';

const HighlightedAppButton = ({
  name,
  backgroundStyle,
  category,
  description,
  link,
  onClick,
}: Props) => {
  return (
    <div
      className={styles.container}
      id="highlighted-app-button"
      onClick={() => {
        onClick?.(name, link);
        if (link.startsWith('/')) history.push(link);
        else window.open(link, '_blank', 'noopener,noreferrer');
      }}
    >
      <div className={styles.highlightedAppBackgroundContainer}>
        <HighlightedAppBackground className={styles.highlightedAppBackground} />
      </div>
      <div className={classnames(styles.textContainer, backgroundStyle)}>
        <div className={styles.category}>{category}</div>
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default HighlightedAppButton;
