import { UseQueryOptions, useQuery } from 'react-query';

import { AgenciesService, Qualification } from 'src/Services/API';

export const useGetQualifications = (
  agencyId?: string,
  companyId?: string,
  config?: UseQueryOptions<Qualification[], Error>
) => {
  return useQuery<Qualification[], Error>(
    ['qualifications', agencyId, companyId],
    async () => {
      const data = await AgenciesService.agenciesControllerGetQualifications({
        agencyId: agencyId!,
        companyId: companyId!,
      });
      data.sort((a, b) => {
        if (a.qualificationLabel > b.qualificationLabel) return 1;
        if (a.qualificationLabel < b.qualificationLabel) return -1;
        return 0;
      });
      return data;
    },
    {
      enabled: Boolean(agencyId && companyId),
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
