import React from 'react';

import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { GoBackArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import Styles from './BackButton.module.scss';
import Props from './BackButton.types';

const BackButton = ({ text, onClick }: Props) => {
  return (
    <div className={Styles.BackButton}>
      <div>
        <Button.Tertiary
          className={Styles.Button}
          leftIcon={<GoBackArrow />}
          onClick={onClick}
          type="button"
        />
      </div>
      <div>{text ?? 'retour'}</div>
    </div>
  );
};

export default BackButton;
