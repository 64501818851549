import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { HomeEditionService, UpdateHomePageConfigParams } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateHomePageConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateHomePageConfig],
    async (params: UpdateHomePageConfigParams) => {
      return await HomeEditionService.homeEditionControllerUpdateHomePageConfig({ body: params });
    },
    {
      onSuccess: () => {
        toast.success('la configuration de la page du launcher a été modifiée');
        queryClient.invalidateQueries(QueryKeys.fetchHomePageConfig);
      },
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error(
              'droits insuffisants pour modifier la configuration de la page du launcher'
            );
            break;
          default:
            toast.error(
              'erreur lors de la modification de la configuration de la page du launcher'
            );
            break;
        }
      },
    }
  );
};
