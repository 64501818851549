import { UseQueryOptions, useQuery } from 'react-query';

import { CalendarEntity, CalendarService } from 'src/Services/API';

export const useGetAgencyCalendar = (
  agencyId: string,
  calendarId: string,
  config?: UseQueryOptions<CalendarEntity, Error>
) => {
  return useQuery<CalendarEntity, Error>(
    ['agency-calendars', agencyId, calendarId],
    async () => {
      const calendars = await CalendarService.calendarControllerGetCalendars({ agencyId });
      const calendar = calendars.find(calendar => calendar.id === calendarId);
      if (!calendar) throw new Error(`Calendar ${calendarId} not found`);
      return calendar;
    },
    {
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
