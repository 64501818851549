import React from 'react';

import { POMIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import HomeParametersPopOverMenu from 'src/Containers/MainPage/TopBar/HomeParametersPopOverMenu';

import styles from './TopBar.module.scss';

const TopBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <POMIcon className={styles.appLogo} />
        <div className={styles.separator} />
        <h1 className={styles.title}>portail mobilité</h1>
      </div>
      <div className={styles.subContainer}>
        <HomeParametersPopOverMenu />
      </div>
    </div>
  );
};
export default TopBar;
