import React, { useCallback, useEffect } from 'react';

import { Router, Switch, useHistory, useLocation } from 'react-router-dom';

import PrivateRoute from 'src/Components/PrivateRoute';
import { useAuth } from 'src/Hooks/Authentication/useAuth';
import { useFetchAuthorizedPages } from 'src/Hooks/Authentication/useFetchAuthorizedPages';
import { security } from 'src/Services/API/Interceptors';
import { LocationState, buildUrl, setTitleAndIcon } from 'src/Services/Routing';
import history from 'src/Utils/history';

import styles from './AdminPage.module.scss';
import { adminNavigation } from './AdminPage.types';
import SideBar from './SideBar';
import { ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION } from './SideBar/SideBar.types';

const AdminPage = () => {
  useEffect(() => {
    setTitleAndIcon('Portail Mobilité | Admin', 'logo192.png');
  });

  const auth = useAuth();
  const userAuthorizedPages = useFetchAuthorizedPages();
  const urlHistory = useHistory();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const path = location.pathname.split('/');
  const firstSection: ADMIN_FIRST_SECTION | undefined =
    path.length > 2
      ? (Object.keys(ADMIN_FIRST_SECTION).find(
          section => section === path[2]
        ) as ADMIN_FIRST_SECTION)
      : undefined;
  const secondSection: ADMIN_SECOND_SECTION | undefined =
    path.length > 3
      ? (Object.keys(ADMIN_SECOND_SECTION).find(
          section => section === path[3]
        ) as ADMIN_SECOND_SECTION)
      : undefined;

  const isPathAccessible = useCallback((): boolean => {
    if (userAuthorizedPages.length === 0) {
      return false;
    }
    if (firstSection === undefined || secondSection === undefined) {
      return false;
    }
    if (
      userAuthorizedPages.find(
        authorizedPage =>
          authorizedPage.firstSection === firstSection &&
          authorizedPage.secondSection === secondSection
      ) === undefined
    ) {
      return false;
    }
    return true;
  }, [firstSection, secondSection, userAuthorizedPages]);

  useEffect(() => {
    if (auth.isLoading) return;
    if (!security.getUserUnitaryRights()) return;
    if (userAuthorizedPages.length === 0) {
      urlHistory.push('/');
    } else {
      if (!isPathAccessible()) {
        const newPath =
          userAuthorizedPages.find(
            authorizedPage => authorizedPage.firstSection === firstSection
          ) ?? userAuthorizedPages[0];
        urlHistory.push(
          buildUrl({
            firstSection: newPath.firstSection,
            secondSection: newPath.secondSection,
          })
        );
      }
    }
  }, [firstSection, isPathAccessible, urlHistory, userAuthorizedPages, auth.isLoading]);
  return (
    <div className={styles.container}>
      <SideBar />
      <div className={styles.contentContainer}>
        {auth.isAuthenticated && (
          <Router history={history}>
            <Switch location={background || location}>
              {adminNavigation.map(firstSectionNavigation => {
                return firstSectionNavigation.secondSections.map(secondSectionNavigation => {
                  return (
                    <PrivateRoute
                      path={buildUrl({
                        firstSection: firstSectionNavigation.firstSection,
                        secondSection: secondSectionNavigation.secondSection,
                      })}
                      necessaryUnitaryRights={secondSectionNavigation.necessaryUnitaryRights}
                      component={secondSectionNavigation.component}
                    />
                  );
                });
              })}
            </Switch>
          </Router>
        )}
      </div>
    </div>
  );
};
export default AdminPage;
