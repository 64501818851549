import React from 'react';
import { useCallback, useState } from 'react';

import { WhiteRightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { SideBarAdminIcon } from 'src/Assets';
import MenuSection from 'src/Components/MenuSection';
import { MenuSection as MenuSectionType } from 'src/Components/MenuSection/MenuSection.types';
import { useFetchAuthorizedPages } from 'src/Hooks/Authentication/useFetchAuthorizedPages';
import { buildUrl, getActiveSection } from 'src/Services/Routing';

import AdminParametersPopOverMenu from './AdminParametersPopOverMenu';
import styles from './SideBar.module.scss';
import { ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION, menuItems } from './SideBar.types';

const SideBar = () => {
  const openedWidth = 176;
  const closedWidth = 64;

  const [opened, setOpened] = useState(true);

  const expand = useSpring({
    minWidth: opened ? `${openedWidth}px` : `${closedWidth}px`,
    maxWidth: opened ? `${openedWidth}px` : `${closedWidth}px`,
  });

  const spin = useSpring({
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  });

  const fade = useSpring({
    opacity: opened ? 1 : 0,
  });
  const history = useHistory();
  const location = useLocation();
  const activeItem = getActiveSection(location.pathname);
  const authorizedPages = useFetchAuthorizedPages();
  const setItem = useCallback(
    ([firstSection, secondSection]: [ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION]) => {
      history.push(
        buildUrl({
          firstSection,
          secondSection,
        })
      );
    },
    [history]
  );

  const availableItems = (item: [string, string]) => {
    return authorizedPages.filter(
      authorizedPage =>
        authorizedPage.firstSection === item[0] && authorizedPage.secondSection === item[1]
    );
  };

  const sectionHasAvailableItems = (
    menuSection: MenuSectionType<[ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION]>
  ) => {
    return menuSection.items.some(
      menuItem => availableItems([menuSection.item, menuItem.item]).length
    );
  };

  return (
    <animated.div style={expand}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <SideBarAdminIcon />
          <animated.div style={fade} className={styles.menuItemContainer}>
            <div className={styles.verticalSeparator} />
            <div className={styles.appTitle}>portail admin</div>
          </animated.div>
        </div>
        {menuItems
          .map(x => {
            return x;
          })
          .filter(menuItem => menuItem.item !== ADMIN_FIRST_SECTION.parameters)
          .filter(sectionHasAvailableItems)
          .map(menuItem => (
            <MenuSection<[ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION]>
              key={menuItem.item}
              {...menuItem}
              opened={opened}
              activeItem={activeItem}
              setItem={setItem}
            />
          ))}
        <div className={styles.bottomButtons}>
          {menuItems
            .filter(menuItem => menuItem.item === ADMIN_FIRST_SECTION.parameters)
            .filter(sectionHasAvailableItems)
            .map(menuItem => (
              <MenuSection<[ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION]>
                key={menuItem.item}
                {...menuItem}
                opened={opened}
                activeItem={activeItem}
                setItem={setItem}
              />
            ))}
          <div className={styles.menuItemContainer}>
            <div className={styles.transparentBorder} />
            <div className={styles.disconnect}>
              <AdminParametersPopOverMenu />
            </div>
          </div>
        </div>
        <div className={styles.retractButton} onClick={() => setOpened(!opened)}>
          <animated.div style={spin} className={styles.retractIcon}>
            <WhiteRightArrow />
          </animated.div>
          <animated.div className={styles.text} style={fade}>
            refermer
          </animated.div>
        </div>
      </div>
    </animated.div>
  );
};

export default SideBar;
