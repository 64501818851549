import React from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';

import { security } from 'src/Services/API/Interceptors';

import PrivateRouteProps from './PrivateRoute.types';

const AuthenticationGuard = ({
  component,
  necessaryUnitaryRights,
}: Pick<PrivateRouteProps, 'component' | 'necessaryUnitaryRights'>) => {
  if (!component) return null;
  if (necessaryUnitaryRights && necessaryUnitaryRights.length) {
    const userUnitaryRights = security.getUserUnitaryRights() ?? [];
    if (!necessaryUnitaryRights.some(right => userUnitaryRights.includes(right)))
      return <div>Access forbidden</div>;
  }

  const Node = withAuthenticationRequired(component, {});

  return <Node />;
};

const PrivateRoute = ({ necessaryUnitaryRights, component, ...routeProps }: PrivateRouteProps) => {
  return (
    <Route {...routeProps}>
      <AuthenticationGuard component={component} necessaryUnitaryRights={necessaryUnitaryRights} />
    </Route>
  );
};

export default PrivateRoute;
