export enum QueryKeys {
  fetchUserUnitaryRights = 'fetchUserUnitaryRights',
  fetchAuthorizedModifiedProfiles = 'fetchAuthorizedModifiedProfiles',
  fetchRights = 'fetchRights',
  fetchProfilesRights = 'fetchProfilesRights',
  fetchCGCs = 'fetchCGCs',
  fetchUnitsWithoutCGCs = 'fetchUnitsWithoutCGCs',
  fetchHomePageConfig = 'fetchHomePageConfig',
  fetchAppNews = 'fetchAppNews',
  fetchAllAppNews = 'fetchAllAppNews',
  fetchAppDetails = 'fetchAppDetails',
}

export enum MutationKeys {
  addRights = 'addRights',
  updateRights = 'updateRights',
  addCGCToUnits = 'addCGCToUnits',
  updateHomePageConfig = 'updateHomePageConfig',
  addAppNews = 'addAppNews',
  updateAppNews = 'updateAppNews',
  updateAppNewsOrder = 'updateAppNewsOrder',
  moveAppNewsToArchivedOrNot = 'moveAppNewsToArchivedOrNot',
  addAppDetails = 'addAppDetails',
  updateAppDetails = 'updateAppDetails',
  deleteAppDetails = 'deleteAppDetails',
}
