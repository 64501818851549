import { UseQueryOptions, useQuery } from 'react-query';

import { CustomLinksResultsDto, CustomLinksService } from 'src/Services/API';

export const useGetCustomLink = (
  link: string,
  config?: UseQueryOptions<CustomLinksResultsDto, Error>
) => {
  return useQuery<CustomLinksResultsDto, Error>(
    ['custom-links', link],
    async () => {
      return await CustomLinksService.customLinksControllerGetOne({ link });
    },
    {
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
