import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { HomeEditionService, UpdateAppNewsOrderParams } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateAppNewsOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateAppNewsOrder],
    async (params: UpdateAppNewsOrderParams) => {
      return await HomeEditionService.homeEditionControllerUpdateAppNewsOrder({ body: params });
    },
    {
      onSuccess: () => {
        toast.success("l'ordre des nouveautés a été mis à jour");
        queryClient.invalidateQueries(QueryKeys.fetchAllAppNews);
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries(QueryKeys.fetchAllAppNews);
        switch (error.response?.status) {
          case 403:
            toast.error("droits insuffisants pour modifier l'ordre des nouveautés");
            break;
          default:
            toast.error("erreur lors de la modification de l'ordre des nouveautés");
            break;
        }
      },
    }
  );
};
