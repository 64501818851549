import { RightsProfile, UnitaryRight } from 'src/Services/API';

export const rightProfileToString = {
  [RightsProfile.SUPERADMIN]: 'superadmin',
  [RightsProfile.ADMIN_CGC]: 'CGC - admin',
  [RightsProfile.EDITOR_CGC]: 'CGC - éditeur',
  [RightsProfile.ADMIN_HOME_EDITION]: 'Patch - admin',
  [RightsProfile.EDITOR_HOME_EDITION]: 'Patch - éditeur',
};

export const unitaryRightsToString = {
  [UnitaryRight.ADD_ADMIN]: 'ajout admin',
  [UnitaryRight.ADD_ADMIN_CGC]: 'ajout admin CGC/unités',
  [UnitaryRight.EDIT_CGC]: 'modif CGC/unités',
  [UnitaryRight.EDIT_QUALIFICATIONS]: 'modif compétences/qualif',
  [UnitaryRight.ADD_ADMIN_HOME_EDITION]: 'ajout admin patchnote',
  [UnitaryRight.EDIT_HOME_EDITION]: 'modif patchnote',
};

export const unitaryRightsToLongString = {
  [UnitaryRight.ADD_ADMIN]: 'ajouter des admin',
  [UnitaryRight.ADD_ADMIN_CGC]: 'ajouter des admin CGC/unités',
  [UnitaryRight.EDIT_CGC]: 'modifier le référentiel CGC/unités',
  [UnitaryRight.EDIT_QUALIFICATIONS]: 'modifier le référentiel compétences/qualif',
  [UnitaryRight.ADD_ADMIN_HOME_EDITION]: 'ajouter des admin patchnote',
  [UnitaryRight.EDIT_HOME_EDITION]: 'modifier la patchnote',
};
