import React, { useState } from 'react';

import { Pom, Settings } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import styles from './AdminParametersPopOverMenu.module.scss';
import { Props } from './AdminParametersPopOverMenu.types';

const AdminParametersPopOverMenu = (props: Props) => {
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  return (
    <ParametersPopup
      trigger={
        <div className={styles.container}>
          <Button
            variant="tertiary"
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Settings />
          </Button>
        </div>
      }
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
      children={
        <Button variant="tertiary" onClick={() => history.push('/')}>
          <Pom />
          revenir au portail mobilité
        </Button>
      }
      {...props}
    />
  );
};
export default AdminParametersPopOverMenu;
