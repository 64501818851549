import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { AppDetail, HomeEditionService } from 'src/Services/API';

import { useAuth } from '../Authentication/useAuth';
import { QueryKeys } from '../types';

export const useFetchAppDetails = (extraOptions?: UseQueryOptions<AppDetail[], AxiosError>) => {
  const { isAuthenticated } = useAuth();

  return useQuery<AppDetail[], AxiosError>(
    [QueryKeys.fetchAppDetails],
    async () => {
      return await HomeEditionService.homeEditionControllerGetAppDetails();
    },
    {
      onError: () => {
        toast.error('erreur lors de la récupération des détails des applications');
      },
      enabled: isAuthenticated,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
