import { UseQueryOptions, useQuery } from 'react-query';

import { CalendarEntity, CalendarService } from 'src/Services/API';

export const useGetAgencyCalendars = (
  agencyId?: string,
  config?: UseQueryOptions<CalendarEntity[] | undefined, Error>
) => {
  return useQuery<CalendarEntity[] | undefined, Error>(
    ['agency-calendars', agencyId],
    async () => {
      if (!agencyId) return undefined;
      return await CalendarService.calendarControllerGetCalendars({ agencyId });
    },
    {
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
