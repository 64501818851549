import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import { Route, Router, Switch, useLocation } from 'react-router-dom';

import history from 'src/Utils/history';

import PrivateRoute from './Components/PrivateRoute';
import AdminPage from './Containers/AdminPage';
import CustomRandy from './Containers/CustomRandy';
import { URL } from './Containers/CustomRandy/CustomRandy.types';
import MainPage from './Containers/MainPage/MainPage.component';
import AddCGCToUnitsModal from './Containers/Modals/CGC/AddCGCToUnitsModal';
import DeleteAppDetailsModal from './Containers/Modals/DeleteAppDetailsModal/DeleteAppDetailsModal.component';
import DeleteAppNewsModal from './Containers/Modals/DeleteAppNewsModal/DeleteAppNewsModal.component';
import EditAppDetailsModal from './Containers/Modals/EditAppDetailsModal/EditAppDetailsModal.component';
import EditAppNewsModal from './Containers/Modals/EditAppNewsModal/EditAppNewsModal.component';
import EditCGCModal from './Containers/Modals/EditCGCModal/EditCGCModal.component';
import DeleteRightModal from './Containers/Modals/Rights/DeleteRightModal';
import EditRightModal from './Containers/Modals/Rights/EditRightModal';
import { useFetchUserUnitaryRights } from './Hooks/Rights/useFetchUserUnitaryRights';
import { UnitaryRight } from './Services/API';
import { setupAPI } from './Services/API/APIConfigurator';
import { security } from './Services/API/Interceptors';
import { LocationState, ModalRoute } from './Services/Routing';
import DebugTools from './Utils/DebugTools';

const modalRouteMap: ModalRoute[] = [
  {
    path: '/admin/parameters/rights/editRight',
    necessaryUnitaryRights: [
      UnitaryRight.ADD_ADMIN,
      UnitaryRight.ADD_ADMIN_CGC,
      UnitaryRight.ADD_ADMIN_HOME_EDITION,
    ],
    component: EditRightModal,
  },
  {
    path: '/admin/parameters/rights/deleteRight',
    necessaryUnitaryRights: [
      UnitaryRight.ADD_ADMIN,
      UnitaryRight.ADD_ADMIN_CGC,
      UnitaryRight.ADD_ADMIN_HOME_EDITION,
    ],
    component: DeleteRightModal,
  },
  {
    path: '/admin/business/cgc/editCGC',
    necessaryUnitaryRights: [UnitaryRight.EDIT_CGC],
    component: EditCGCModal,
  },
  {
    path: '/admin/business/cgc/addCGCToUnits',
    necessaryUnitaryRights: [UnitaryRight.EDIT_CGC],
    component: AddCGCToUnitsModal,
  },
  {
    path: '/admin/apps/home/editAppNews',
    necessaryUnitaryRights: [UnitaryRight.EDIT_HOME_EDITION],
    component: EditAppNewsModal,
  },
  {
    path: '/admin/apps/home/deleteAppNews',
    necessaryUnitaryRights: [UnitaryRight.EDIT_HOME_EDITION],
    component: DeleteAppNewsModal,
  },
  {
    path: '/admin/apps/home/editAppDetails',
    necessaryUnitaryRights: [UnitaryRight.EDIT_HOME_EDITION],
    component: EditAppDetailsModal,
  },
  {
    path: '/admin/apps/home/deleteAppDetails',
    necessaryUnitaryRights: [UnitaryRight.EDIT_HOME_EDITION],
    component: DeleteAppDetailsModal,
  },
];

function RouteSwitch() {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  return (
    <DebugTools>
      <>
        <Switch location={background || location}>
          <Route exact path="/" component={MainPage} />
          <PrivateRoute path={`/${URL}`} component={CustomRandy} />
          <PrivateRoute path="/admin" component={AdminPage} />
        </Switch>
        {modalRouteMap.map(modalInfo => (
          <PrivateRoute
            key={modalInfo.path}
            path={modalInfo.path}
            necessaryUnitaryRights={modalInfo.necessaryUnitaryRights}
            component={modalInfo.component}
          />
        ))}
        <ToastContainer position="bottom-right" />
      </>
    </DebugTools>
  );
}
function App() {
  const { getAccessTokenSilently, logout } = useAuth0();
  const userUnitaryRights = useFetchUserUnitaryRights();
  security.setUserUnitaryRights(userUnitaryRights.data ?? undefined);
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);
  useEffect(() => setupAPI(), []);

  return (
    <div className="App">
      <Router history={history}>
        <RouteSwitch />
      </Router>
    </div>
  );
}

export default App;
