import React from 'react';

import { useFetchCGCs } from 'src/Hooks/CGC/useFetchCGCs';

import TopBar from '../TopBar';

import CGCs from './CGCs/CGCs.component';
import styles from './CGCSection.module.scss';
import UnitsWithCGC from './UnitsWithCGC';
import UnitsWithoutCGC from './UnitsWithoutCGC';

const CGCSection = () => {
  const { data: cgcs, isError, isLoading } = useFetchCGCs();
  return (
    <>
      <TopBar />
      <div className={styles.content}>
        <div className={styles.unitsPart}>
          <div className={styles.unitsWithoutCGCPart}>
            <UnitsWithoutCGC />
          </div>
          <div className={styles.unitsWithCGCPart}>
            <div className={styles.partTitle}>rechercher une unité</div>
            <UnitsWithCGC cgcs={cgcs ?? []} isError={isError} isLoading={isLoading} />
          </div>
        </div>
        <div className={styles.CGCPart}>
          <div className={styles.partTitle}>rechercher un CGC</div>
          <CGCs cgcs={cgcs ?? []} isError={isError} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};
export default CGCSection;
