import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { PutUnitsToCGCParam, SkillsManagementCentersService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useAddUnitsToCGC = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.addCGCToUnits],
    async (params: PutUnitsToCGCParam) => {
      return SkillsManagementCentersService.skillsManagementCentersControllerAddUnitsToCgc({
        body: params,
      });
    },
    {
      onSuccess: () => {
        toast.success('les unités ont été attribuées au CGC sélectionné');
        queryClient.invalidateQueries(QueryKeys.fetchCGCs);
        queryClient.invalidateQueries(QueryKeys.fetchUnitsWithoutCGCs);
      },
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour attribuer ces unités au CGC sélectionné');
            break;
          default:
            toast.error("erreur lors de l'attribution des unités au CGC sélectionné");
            break;
        }
      },
    }
  );
};
