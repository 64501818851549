import React, { useMemo, useState } from 'react';

import {
  Button,
  HorizontalCardWithTitle,
  Loader,
  TextInput as RawTextInput,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  Cross,
  Filters,
  PlusCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import CustomDropdownComponent from 'src/Components/CustomDropdown/CustomDropdownComponent';
import Filet from 'src/Components/Filet';
import InputLabel from 'src/Components/InputLabel';
import { useGetCustomLinksList } from 'src/Hooks/CustomRandy/useGetCustomLinksList';
import { OfferStatus } from 'src/Services/API';
import history from 'src/Utils/history';

import ErrorMessage from '../../../../Components/ErrorMessage';
import { CustomRandySection, CustomRandySubSection, URL } from '../../CustomRandy.types';

import Styles from './ListCustomLinksScreen.module.scss';

const ListCustomLinksScreen = () => {
  const { data, isLoading, error } = useGetCustomLinksList();
  const agencies = useMemo(() => {
    const agencies = [...new Set(data?.map(({ agencyId }) => agencyId))];
    return agencies;
  }, [data]);

  const [filterQuery, setFilterQuery] = useState('');
  const [filterAgency, setFilterAgency] = useState<string | undefined>();
  const filteredData = useMemo(
    () =>
      data
        ?.filter(customLink =>
          customLink.qualificationLabel
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^A-Za-z0-9 ]/g, '')
            .toUpperCase()
            .includes(
              filterQuery
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/[^A-Za-z0-9 ]/g, '')
                .toUpperCase()
            )
        )
        .filter(customLink => (filterAgency ? filterAgency === customLink.agencyId : true)) ?? [],
    [data, filterQuery, filterAgency]
  );

  return (
    <div className={Styles.ListCustomLinksScreen}>
      <div className={Styles.TitleBar}>
        <h2>liste des liens existants</h2>
        <Button.Primary.XSmall
          rightIcon={<PlusCircle />}
          text="créer un lien"
          onClick={() =>
            history.push(`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}/add`)
          }
        />
      </div>
      {isLoading && <Loader heightInRem={2} darkMode />}
      {error && <ErrorMessage error={error} />}
      {data && !data.length && "vous n'avez pas créé de lien personnalisé"}
      {data && data.length > 0 && (
        <>
          <div className={Styles.Filters}>
            <div>filtrer par</div>
            <InputLabel label="filtrer par agence">
              <CustomDropdownComponent
                placeholder="agence"
                items={agencies}
                selectedItem={filterAgency}
                onSelectItem={setFilterAgency}
                canBeReset
                triggerIcon={<Filters />}
              />
            </InputLabel>
            <InputLabel label="filtrer par qualification">
              <RawTextInput
                placeholder="ex: Cariste, Technicien"
                rightIcon={filterQuery ? <Cross className={Styles.Cross} /> : undefined}
                value={filterQuery}
                onChange={event => {
                  setFilterQuery(event.currentTarget.value);
                }}
                onRightIconClick={() => {
                  setFilterQuery('');
                }}
                leftIcon={<Filters />}
              />
            </InputLabel>
          </div>

          <Filet />

          {!filteredData.length && `aucun des liens ne correspond à « ${filterQuery} »`}
          <div>
            {filteredData.map(customLink => (
              <HorizontalCardWithTitle
                key={customLink.offerLink}
                title={`agence ${customLink.agencyId} - ${customLink.companyName} (siret: ${customLink.companySiret})`}
                subtitles={[customLink.qualificationLabel]}
                onClick={() => {
                  history.push(
                    `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}/${customLink.offerLink}`
                  );
                }}
                rightActions={
                  customLink.offerStatus === OfferStatus.ACTIVE ? (
                    <div className={classnames(Styles.Status, Styles.Active)}>
                      lien actif
                      <div className={Styles.Tooltip}>des critères existent dans Osmose</div>
                    </div>
                  ) : (
                    <div className={Styles.Status}>
                      lien inactif
                      <div className={Styles.Tooltip}>pas de critères dans Osmose</div>
                    </div>
                  )
                }
                className={Styles.Card}
              />
            ))}
          </div>

          <Button.Primary.Medium
            rightIcon={<PlusCircle />}
            text="créer un lien"
            onClick={() =>
              history.push(`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}/add`)
            }
          />
        </>
      )}
    </div>
  );
};

export default ListCustomLinksScreen;
