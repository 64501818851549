import { UseMutationOptions, useMutation } from 'react-query';

import { AgenciesService, AgencyConfigDto } from 'src/Services/API';

export const useUpdateAgencyConfig = (
  config?: UseMutationOptions<void, Error, { agencyId: string; body: AgencyConfigDto }, unknown>
) => {
  return useMutation(async params => {
    return await AgenciesService.agenciesControllerUpdateConfig(params);
  }, config);
};
