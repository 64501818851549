import React, { useState } from 'react';

import { Administration, Settings } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { security } from 'src/Services/API/Interceptors';

import styles from './HomeParametersPopOverMenu.module.scss';
import { Props } from './HomeParametersPopOverMenu.types';

const HomeParametersPopOverMenu = (props: Props) => {
  const userUnitaryRights = security.getUserUnitaryRights();
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);

  return (
    <ParametersPopup
      trigger={
        <div>
          <Button
            variant="tertiary"
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Settings className={isOpened ? styles.gearIcon : undefined} />
          </Button>
        </div>
      }
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
      children={
        userUnitaryRights !== undefined && userUnitaryRights.length > 0 ? (
          <Button variant="tertiary" onClick={() => history.push('/admin')}>
            <Administration />
            accéder à l'administration
          </Button>
        ) : undefined
      }
      {...props}
    />
  );
};
export default HomeParametersPopOverMenu;
