import React, { useState } from 'react';

import { SegmentedControl } from '@randstad-lean-mobile-factory/react-components-core';

import TopBar from '../TopBar';

import AppNewsEdition from './AppNewsEdition/AppNewsEdition.component';
import AppsDetailsEdition from './AppsDetailsEdition/AppsDetailsEdition.component';
import styles from './HomeEditionSection.module.scss';
import HomePageConfigEdition from './HomePageConfigEdition/HomePageConfigEdition.component';

enum Segment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const MapSegmentToText = {
  [Segment.LEFT]: 'colonne gauche',
  [Segment.RIGHT]: 'liste des apps',
};

const HomeEditionSection = () => {
  const [selectedSegment, setSelectedSegment] = useState(Segment.LEFT);
  return (
    <>
      <TopBar />
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <SegmentedControl
              onSelectionChange={segment => {
                setSelectedSegment(segment);
              }}
              selected={selectedSegment}
              controls={Object.values(Segment)}
              getValue={segment => MapSegmentToText[segment]}
              labelClassName={styles.switchButtonText}
              className={styles.switchButton}
              selectedBackgroundClassName={styles.selectedSwitchButtonBackground}
            />
          </div>
          {selectedSegment === Segment.LEFT && (
            <>
              <HomePageConfigEdition />
              <div className={styles.sectionSeparator} />
              <AppNewsEdition />
            </>
          )}
          {selectedSegment === Segment.RIGHT && <AppsDetailsEdition />}
        </div>
      </div>
    </>
  );
};
export default HomeEditionSection;
