import React, { useCallback, useEffect, useState } from 'react';

import classnames from 'classnames';

import Styles from './InputLabel.module.scss';
import Props from './InputLabel.types';

const InputLabel = ({ label, explanation, children, className }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = useCallback(() => {
    setShowTooltip(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleClick);
    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <label>
      <div className={classnames(Styles.InputLabel, className)}>
        <div className={Styles.LabelText}>{label}</div>
        {explanation && (
          <div
            className={Styles.ExplainButton}
            onClick={event => {
              event.preventDefault();
              setTimeout(() => setShowTooltip(true));
            }}
          >
            qu'est-ce que c'est ?
            <div className={classnames(Styles.Tooltip, showTooltip && Styles.Enabled)}>
              {explanation}
            </div>
          </div>
        )}
      </div>
      {children}
    </label>
  );
};

export default InputLabel;
