import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { HomeEditionService, UpdateAppNewsParams } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useAddAppNews = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.addAppNews],
    async (params: UpdateAppNewsParams) => {
      return HomeEditionService.homeEditionControllerAddAppNews({ body: params });
    },
    {
      onSuccess: () => {
        toast.success('la nouveauté a été créée');
        queryClient.invalidateQueries(QueryKeys.fetchAllAppNews);
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries(QueryKeys.fetchAllAppNews);
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour créer une nouveauté');
            break;
          default:
            toast.error('erreur lors de la création de la nouveauté');
            break;
        }
      },
    }
  );
};
