import { UseMutationOptions, useMutation } from 'react-query';

import { CalendarDto, CalendarService } from 'src/Services/API';

export const useAddAgencyCalendar = (
  config?: UseMutationOptions<string, Error, { agencyId: string; body: CalendarDto }, unknown>
) => {
  return useMutation(async params => {
    return await CalendarService.calendarControllerAddCalendar(params);
  }, config);
};
