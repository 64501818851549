import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';

import { SkillsManagementCentersService, UnitsWithTotalCount } from 'src/Services/API';

import { useAuth } from '../Authentication/useAuth';
import { QueryKeys } from '../types';

const DEFAULT_UNITS_PER_PAGE = 20;

export const useFetchUnitsWithoutCGCs = (
  extraOptions?: UseInfiniteQueryOptions<UnitsWithTotalCount, AxiosError>
) => {
  const { isAuthenticated } = useAuth();

  return useInfiniteQuery<UnitsWithTotalCount, AxiosError>(
    [QueryKeys.fetchUnitsWithoutCGCs],
    async ({ pageParam = 0 }) => {
      return await SkillsManagementCentersService.skillsManagementCentersControllerGetUnitsWithoutCgCs(
        { offset: pageParam * DEFAULT_UNITS_PER_PAGE, limit: DEFAULT_UNITS_PER_PAGE }
      );
    },
    {
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour accéder aux unités sans CGC');
            break;
          default:
            toast.error('erreur lors de la récupération des unités sans CGC');
        }
      },
      getNextPageParam: (lastPage, allPages) =>
        lastPage.units.length < DEFAULT_UNITS_PER_PAGE ? undefined : allPages.length,
      enabled: isAuthenticated,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
