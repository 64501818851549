import { UnitaryRight } from 'src/Services/API';

import CGCSection from './CGCSection';
import HomeEditionSection from './HomeEditionSection';
import QualificationsSection from './QualificationsSection';
import RightsSection from './RightsSection';
import { ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION } from './SideBar/SideBar.types';

interface SecondSectionNavigation {
  secondSection: ADMIN_SECOND_SECTION;
  necessaryUnitaryRights: UnitaryRight[];
  component?: () => JSX.Element;
}

interface FirstSectionNavigation {
  firstSection: ADMIN_FIRST_SECTION;
  secondSections: SecondSectionNavigation[];
}

type AdminNavigation = FirstSectionNavigation[];

export const adminNavigation: AdminNavigation = [
  {
    firstSection: ADMIN_FIRST_SECTION.business,
    secondSections: [
      {
        secondSection: ADMIN_SECOND_SECTION.cgc,
        necessaryUnitaryRights: [UnitaryRight.EDIT_CGC],
        component: CGCSection,
      },
      {
        secondSection: ADMIN_SECOND_SECTION.qualifications,
        necessaryUnitaryRights: [UnitaryRight.EDIT_QUALIFICATIONS],
        component: QualificationsSection,
      },
    ],
  },
  {
    firstSection: ADMIN_FIRST_SECTION.apps,
    secondSections: [
      {
        secondSection: ADMIN_SECOND_SECTION.home,
        necessaryUnitaryRights: [UnitaryRight.EDIT_HOME_EDITION],
        component: HomeEditionSection,
      },
    ],
  },
  {
    firstSection: ADMIN_FIRST_SECTION.parameters,
    secondSections: [
      {
        secondSection: ADMIN_SECOND_SECTION.rights,
        necessaryUnitaryRights: [
          UnitaryRight.ADD_ADMIN,
          UnitaryRight.ADD_ADMIN_CGC,
          UnitaryRight.ADD_ADMIN_HOME_EDITION,
        ],
        component: RightsSection,
      },
    ],
  },
];
