import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { HomeEditionService, UpdateAppDetailsParams } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useUpdateAppDetails = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateAppDetails],
    async (params: UpdateAppDetailsParams) => {
      return await HomeEditionService.homeEditionControllerUpdateAppDetails({ body: params });
    },
    {
      onSuccess: () => {
        toast.success("les détails de l'app ont été modifiés");
        queryClient.invalidateQueries(QueryKeys.fetchAppDetails);
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries(QueryKeys.fetchAppDetails);
        switch (error.response?.status) {
          case 403:
            toast.error("droits insuffisants pour modifier les détails de l'app");
            break;
          default:
            toast.error("erreur lors de la modification des détails de l'app");
            break;
        }
      },
    }
  );
};
