import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { DeleteAppNewsParams, HomeEditionService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useDeleteAppNews = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateAppNews],
    async (params: DeleteAppNewsParams) => {
      return await HomeEditionService.homeEditionControllerDeleteAppNews({ body: params });
    },
    {
      onSuccess: () => {
        toast.success("la nouveauté sur l'application a été supprimée");
        queryClient.invalidateQueries(QueryKeys.fetchAllAppNews);
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries(QueryKeys.fetchAllAppNews);
        switch (error.response?.status) {
          case 403:
            toast.error("droits insuffisants pour supprimer la nouveauté sur l'application");
            break;
          default:
            toast.error("erreur lors de la suppression de la nouveauté sur l'application");
        }
      },
    }
  );
};
