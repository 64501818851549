import React, { useState } from 'react';

import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Pencil,
  ThreeDots,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import { EditionRightMode } from 'src/Containers/Modals/Rights/EditRightModal/EditRightModal.types';
import { useModalWithLocation } from 'src/Hooks/Navigation';

import styles from './RightActions.module.scss';
import { Props } from './RightActions.types';

const RightActions = ({ right }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const editRightModal = useModalWithLocation('editRight');
  const deleteRightModal = useModalWithLocation('deleteRight');
  return (
    <PopupMenu
      position={'left top'}
      width={250}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpen })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        key="editRight"
        icon={<Pencil />}
        text="modifier les droits"
        onClick={() => {
          editRightModal.open({
            modalParameters: { editionRightMode: EditionRightMode.MODIFY, right },
          });
        }}
      />
      <PopupMenu.Item
        key="deleteRight"
        icon={<Trashcan />}
        text="supprimer les droits"
        onClick={() => {
          deleteRightModal.open({
            modalParameters: { consultantSiid: right.consultantSiid },
          });
        }}
      />
    </PopupMenu>
  );
};

export default RightActions;
