import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { CGCWithUnits, SkillsManagementCentersService } from 'src/Services/API';

import { useAuth } from '../Authentication/useAuth';
import { QueryKeys } from '../types';

export const useFetchCGCs = (extraOptions?: UseQueryOptions<CGCWithUnits[], AxiosError>) => {
  const { isAuthenticated } = useAuth();

  return useQuery<CGCWithUnits[], AxiosError>(
    [QueryKeys.fetchCGCs],
    async () => {
      return await SkillsManagementCentersService.skillsManagementCentersControllerGetCgCs();
    },
    {
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour accéder aux CGCs');
            break;
          default:
            toast.error('erreur lors de la récupération des CGCs');
        }
      },
      enabled: isAuthenticated,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
