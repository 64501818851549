import { LogoutOptions } from '@auth0/auth0-react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import history from 'src/Utils/history';

import { UnitaryRight } from '.';

let userUnitaryRights: UnitaryRight[] | undefined = undefined;
let getAccessTokenSilently: (() => Promise<string>) | null = null;
let logout: ((options?: LogoutOptions | undefined) => void) | null = null;

export const security = {
  getUserUnitaryRights: () => userUnitaryRights,
  setUserUnitaryRights: (newValue: UnitaryRight[] | undefined) => (userUnitaryRights = newValue),
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: () => Promise<string>) => (getAccessTokenSilently = func),
  logout: () => logout,
  setLogout: (func: (options?: LogoutOptions | undefined) => void) => (logout = func),
};

export async function requestInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const token = await security.getAccessTokenSilently()?.();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
}

export async function errorInterceptor(error: AxiosError): Promise<AxiosError> {
  const { response, request, message, config } = error;
  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // eslint-disable-next-line no-magic-numbers
    if (config && response.status === 401) {
      security.logout()?.();
      history.push('/login');
    }
    return Promise.reject(
      new Error(response.data.message ?? 'Error', { cause: response.data.error })
    );
  } else if (request) {
    // The request was made but no response was received
    // `request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', message);
  }

  // Either throw or return a rejected promise, will resolve in then otherwise
  return Promise.reject(error);
}
