import React, { useEffect, useState } from 'react';

import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import { Flux } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import ContentLoader from 'react-content-loader';
import { useInView } from 'react-intersection-observer';

import { EditionCGCMode } from 'src/Containers/Modals/CGC/AddCGCToUnitsModal/AddCGCToUnitsModal.types';
import { useFetchUnitsWithoutCGCs } from 'src/Hooks/CGC/useFetchUnitsWithoutCGCs';
import { useModalWithLocation } from 'src/Hooks/Navigation';
import { Unit } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './UnitsWithoutCGC.module.scss';
import UnitsWithoutCGCActions from './UnitsWithoutCGCActions';

const UnitsWithoutCGC = () => {
  const addCGCToUnits = useModalWithLocation('addCGCToUnits');

  const [unitsWithoutCGCs, setUnitsWithoutCGCs] = useState<Unit[]>([]);
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const {
    data: unitsWithoutCGCsPages,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useFetchUnitsWithoutCGCs();

  const { ref, inView } = useInView();

  useEffect(() => {
    const allUnits: Unit[] = [];
    unitsWithoutCGCsPages?.pages.forEach(({ units }) => allUnits.push(...units));
    setUnitsWithoutCGCs(allUnits);
  }, [unitsWithoutCGCsPages]);

  useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div className={styles.container}>
      <div className={styles.partTitle}>
        <div className={styles.partTitleText}>
          {unitsWithoutCGCsPages !== undefined
            ? pluralFormat(unitsWithoutCGCsPages.pages[0].totalCount, 'unité')
            : 'unité(s)'}
          {' sans CGC'}
        </div>
        {unitsWithoutCGCs !== undefined && unitsWithoutCGCs.length > 0 && (
          <UnitsWithoutCGCActions
            allUnitsWithoutCGC={unitsWithoutCGCs ?? []}
            selectedUnits={selectedUnits}
            setSelectedUnits={setSelectedUnits}
          />
        )}
      </div>
      <div className={styles.unitsWithoutCGCListContainer}>
        {unitsWithoutCGCs !== undefined &&
          unitsWithoutCGCs.map(unitWithoutCGC => (
            <div key={unitWithoutCGC.id} className={styles.unit}>
              <div className={styles.separator} />
              <Checkbox
                className={styles.unitCheckbox}
                checked={
                  selectedUnits.find(selectedUnit => selectedUnit === unitWithoutCGC.id) !==
                  undefined
                }
                onChange={() => {
                  if (
                    selectedUnits.find(selectedUnit => selectedUnit === unitWithoutCGC.id) !==
                    undefined
                  ) {
                    setSelectedUnits(
                      selectedUnits.filter(selectedUnit => selectedUnit !== unitWithoutCGC.id)
                    );
                  } else {
                    setSelectedUnits([...selectedUnits, unitWithoutCGC.id]);
                  }
                }}
                children={
                  <div key={unitWithoutCGC.id} className={styles.unitCard}>
                    <div>{unitWithoutCGC.id}</div>
                    <Flux
                      onClick={event => {
                        event.preventDefault();
                        setSelectedUnits([]);
                        addCGCToUnits.open({
                          modalParameters: {
                            editionCGCMode: EditionCGCMode.ADD_UNIT,
                            units: [unitWithoutCGC.id],
                          },
                        });
                      }}
                    />
                  </div>
                }
              />
            </div>
          ))}
        <div ref={ref}>
          {hasNextPage && (
            <ContentLoader height="3rem" width="100%" uniqueKey="UnitsWithoutCGCLoader">
              <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="3rem" />
            </ContentLoader>
          )}
        </div>
      </div>
      {isLoading && (
        <>
          <div className={styles.loadingText}>patience, cette recherche prend du temps...</div>
          <ContentLoader height="11rem" width="100%" uniqueKey="UnitsWithoutCGCLoader">
            <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="3rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="4rem" width="100%" height="3rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="8rem" width="100%" height="3rem" />
          </ContentLoader>
        </>
      )}
      {isError && <p>Erreur lors de la récupération des unités sans CGC</p>}
    </div>
  );
};
export default UnitsWithoutCGC;
