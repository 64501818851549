import { UseMutationOptions, useMutation } from 'react-query';

import { CustomLinksService, OfferInfoDto } from 'src/Services/API';

export const useCreateCustomLink = (
  config?: UseMutationOptions<string, Error, OfferInfoDto, unknown>
) => {
  return useMutation(async query => {
    return await CustomLinksService.customLinksControllerCreateLink({ body: query });
  }, config);
};
