import React, { useEffect, useState } from 'react';

import {
  Button,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Flux } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import ContentLoader from 'react-content-loader';

import { EditionCGCMode } from 'src/Containers/Modals/CGC/AddCGCToUnitsModal/AddCGCToUnitsModal.types';
import { useModalWithLocation } from 'src/Hooks/Navigation';

import { UnitWithCGC } from '../CGCSection.types';

import styles from './UnitsWithCGC.module.scss';
import { Props } from './UnitsWithCGC.types';
import { getUnitsFromCGCs } from './utils';

const UnitsWithCGC = ({ cgcs, isLoading, isError }: Props) => {
  const addCGCToUnits = useModalWithLocation('addCGCToUnits');
  const [unitsWithCGCsKeyword, setUnitsWithCGCsKeyword] = useState('');
  const [filteredUnitsWithCGCs, setFilteredUnitsWithCGCs] = useState<UnitWithCGC[]>([]);

  useEffect(() => {
    setFilteredUnitsWithCGCs(
      unitsWithCGCsKeyword.length > 0
        ? getUnitsFromCGCs({ cgcs, keyword: unitsWithCGCsKeyword })
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitsWithCGCsKeyword, cgcs]);
  return (
    <>
      <WithLightTitle title="trouvez une unité et son CGC attribué">
        <TextInput
          value={unitsWithCGCsKeyword}
          placeholder="1 caractère minimum"
          onChange={event => setUnitsWithCGCsKeyword((event.target as HTMLInputElement).value)}
        />
      </WithLightTitle>
      <div className={styles.unitsWithCGCListContainer}>
        {filteredUnitsWithCGCs.map(unitWithCGC => (
          <div key={unitWithCGC.unit.id}>
            <div className={styles.separator} />
            <div className={styles.unitCard}>
              <div className={styles.unitTexts}>
                <div className={styles.unitName}>{unitWithCGC.unit.id}</div>
                <div className={styles.unitCGC}>{unitWithCGC.cgc.label}</div>
              </div>
              <div>
                <Button.Tertiary className={styles.editButton}>
                  <Flux
                    onClick={() => {
                      addCGCToUnits.open({
                        modalParameters: {
                          editionCGCMode: EditionCGCMode.REASSIGN_UNIT,
                          units: [unitWithCGC.unit.id],
                          previousCGC: unitWithCGC.cgc.id,
                        },
                      });
                    }}
                  />
                </Button.Tertiary>
              </div>
            </div>
          </div>
        ))}
        {unitsWithCGCsKeyword.length > 0 && isLoading && (
          <ContentLoader height="12rem" width="100%" uniqueKey="UnitsWithCGCLoader">
            <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="3rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="4rem" width="100%" height="3rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="8rem" width="100%" height="3rem" />
          </ContentLoader>
        )}
        {isError && <p>Erreur lors de la récupération des CGC</p>}
      </div>
    </>
  );
};
export default UnitsWithCGC;
