import React, { useState } from 'react';

import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  BackTo,
  ListCheck,
  ThreeDots,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import { EditionCGCMode } from 'src/Containers/Modals/CGC/AddCGCToUnitsModal/AddCGCToUnitsModal.types';
import { useModalWithLocation } from 'src/Hooks/Navigation';

import styles from './UnitsWithoutCGCActions.module.scss';
import { Props } from './UnitsWithoutCGCActions.types';

const UnitsWithoutCGCActions = ({ allUnitsWithoutCGC, selectedUnits, setSelectedUnits }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const addCGCToUnits = useModalWithLocation('addCGCToUnits');
  return (
    <PopupMenu
      position={'bottom right'}
      width={250}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpen })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      {allUnitsWithoutCGC.length !== 0 && (
        <PopupMenu.Item
          key="selectAll"
          icon={<ListCheck />}
          text={
            selectedUnits.length === allUnitsWithoutCGC.length
              ? 'tout désélectionner'
              : 'tout sélectionner'
          }
          onClick={() => {
            setSelectedUnits(
              selectedUnits.length === allUnitsWithoutCGC.length
                ? []
                : allUnitsWithoutCGC.map(unit => unit.id)
            );
          }}
        />
      )}
      {selectedUnits.length > 0 && selectedUnits.length !== allUnitsWithoutCGC.length && (
        <PopupMenu.Item
          key="deselect"
          icon={<BackTo />}
          text="désélectionner"
          onClick={() => {
            setSelectedUnits([]);
          }}
        />
      )}
      {selectedUnits.length > 0 && (
        <div>
          <div className={styles.popupMenuSubTitle}>actions</div>
          <PopupMenu.Item
            key="addCGCToUnit"
            text="attribuer à un CGC"
            onClick={() => {
              setSelectedUnits([]);
              addCGCToUnits.open({
                modalParameters: { editionCGCMode: EditionCGCMode.ADD_UNIT, units: selectedUnits },
              });
            }}
          />
        </div>
      )}
    </PopupMenu>
  );
};

export default UnitsWithoutCGCActions;
