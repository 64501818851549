import React from 'react';

import classnames from 'classnames';
import { animated, useSpring } from 'react-spring';

import Styles from './MenuItem.module.scss';
import Props from './MenuItem.types';

const MenuItem = <TItem extends [string, string]>({
  title,
  item,
  iconSelected,
  iconNotSelected,
  parent,
  opened,
  activeItem,
  setItem,
}: Props<TItem>) => {
  const fade = useSpring({
    opacity: opened ? 1 : 0,
  });

  const selected = activeItem[0] === parent && activeItem[1] === item;

  return (
    <div className={Styles.menuItemContainer}>
      <div className={selected ? Styles.whiteBorder : Styles.transparentBorder} />
      <div
        className={classnames(Styles.item, {
          [Styles.selected]: selected,
          [Styles.closedSelected]: selected && !opened,
        })}
      >
        <animated.div
          className={Styles.animatedContainer}
          onClick={() => {
            setItem([parent, item] as TItem);
          }}
        >
          {selected ? iconSelected : iconNotSelected}
          <animated.div className={Styles.text} style={fade}>
            {title}
          </animated.div>
        </animated.div>
      </div>
      {selected && <div className={Styles.triangle}></div>}
    </div>
  );
};

export default MenuItem;
