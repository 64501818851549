import React from 'react';

import styles from './TopBarTitles.module.scss';
import { Props } from './TopBarTitles.types';

const TopBarTitles = ({ title, subtitle }: Props) => (
  <div>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{subtitle}</div>
  </div>
);
export default TopBarTitles;
