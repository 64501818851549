import React, { useCallback } from 'react';

import { Cross, LightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import Select from 'react-select';

import Styles from './CustomDropdownComponent.module.scss';
import Props, { AlwaysDefined } from './CustomDropdownComponent.types';
import CustomDropdownOption from './CustomDropdownOption';
import { OptionType } from './CustomDropdownOption/CustomDropdownOption.types';

const CustomDropdownComponent = <T,>({
  items,
  disabled,
  placeholder,
  keyValueExtractor,
  onSelectItem,
  selectedItem,
  canBeReset,
  triggerIcon,
  isError,
}: Props<T>) => {
  const toOptionType = useCallback(
    (item: AlwaysDefined<T>) => {
      return keyValueExtractor
        ? keyValueExtractor(item)
        : { item, value: item?.toString() ?? '', label: item?.toString() ?? '' };
    },
    [keyValueExtractor]
  );

  return (
    <div className={Styles.CustomDropdownComponent}>
      <Select<OptionType<AlwaysDefined<T>>>
        options={items.map(toOptionType)}
        value={selectedItem !== undefined ? toOptionType(selectedItem) : null}
        isDisabled={disabled}
        isClearable={canBeReset}
        getOptionValue={({ value }) => value}
        getOptionLabel={({ label }) => label}
        classNames={{
          control: state =>
            classnames(
              Styles.Select,
              state.isFocused && Styles.Focused,
              state.isDisabled && Styles.Disabled,
              isError && Styles.Error,
              state.menuIsOpen && Styles.Open
            ),
          valueContainer: () => Styles.ValueContainer,
          placeholder: () => Styles.Placeholder,
          indicatorSeparator: () => Styles.IndicatorSeparator,
          indicatorsContainer: () => Styles.IndicatorContainer,
          menu: () => Styles.Menu,
          menuList: () => Styles.MenuList,
        }}
        onChange={value => onSelectItem?.(value?.item)}
        placeholder={placeholder ?? ''}
        components={{
          ValueContainer: ({ children }) => (
            <div className={Styles.ValueContainer}>
              {triggerIcon}
              <div className={Styles.ValueContainerChildren}>{children}</div>
            </div>
          ),
          DropdownIndicator: () => <LightArrow className={Styles.Arrow} />,
          Option: CustomDropdownOption,
          ClearIndicator: ({ clearValue }) => (
            <Cross className={Styles.CrossIcon} onClick={clearValue} />
          ),
          NoOptionsMessage: () => <div className={Styles.NoOptionsMessage}>pas de résultats</div>,
        }}
      />
    </div>
  );
};

export default CustomDropdownComponent;
