import React from 'react';

import { UnderConstruction } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import styles from './UnderContructionSection.module.scss';

const UnderContructionSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <UnderConstruction />
      </div>
      <div className={styles.title}>Page en construction.</div>
      <div className={styles.title}>Bientôt sur vos écrans…</div>
    </div>
  );
};

export default UnderContructionSection;
