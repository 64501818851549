import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { ReassignUnitToCGCParam, SkillsManagementCentersService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useReassignUnitToCGC = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.addCGCToUnits],
    async (params: ReassignUnitToCGCParam) => {
      return SkillsManagementCentersService.skillsManagementCentersControllerReassignUnitToCgc({
        body: params,
      });
    },
    {
      onSuccess: () => {
        toast.success("l'unité ont été réassignée au CGC sélectionné");
        queryClient.invalidateQueries(QueryKeys.fetchCGCs);
      },
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour réassigner cette unité au CGC sélectionné');
            break;
          default:
            toast.error("erreur lors de la mise à jour du CGC lié à l'unité");
            break;
        }
      },
    }
  );
};
