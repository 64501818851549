import { combineReducers } from '@reduxjs/toolkit';

import { AppDispatch } from './store';

const rootReducer = combineReducers({
  placeholder: (state = {}, _) => state,
  // insert your own reducers here
  // see https://redux-toolkit.js.org/api/createSlice for documentation
  // see https://redux-toolkit.js.org/api/createAsyncThunk for async actions handling
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  dispatch: AppDispatch;
  state: RootState;
  extra?: unknown;
  rejectValue?: unknown;
}

export default rootReducer;
