import { UseMutationOptions, useMutation } from 'react-query';

import { CustomLinksService, UpdateLinkDto } from 'src/Services/API';

export const useUpdateCustomLink = (
  config?: UseMutationOptions<void, Error, { link: string; body: UpdateLinkDto }, unknown>
) => {
  return useMutation(async params => {
    return await CustomLinksService.customLinksControllerUpdateLink(params);
  }, config);
};
