import React from 'react';

import UnderContructionSection from 'src/Components/UnderContructionSection';

import TopBar from '../TopBar';

import styles from './QualificationsSection.module.scss';

const QualificationsSection = () => {
  return (
    <>
      <TopBar />
      <div className={styles.content}>
        <UnderContructionSection />
      </div>
    </>
  );
};
export default QualificationsSection;
