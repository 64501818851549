import { CGCWithUnits } from 'src/Services/API';

import { UnitWithCGC } from '../CGCSection.types';

interface Props {
  cgcs: CGCWithUnits[];
  keyword: string;
}

export const getUnitsFromCGCs = ({ cgcs, keyword }: Props): UnitWithCGC[] => {
  const allUnitsFromCGCs: UnitWithCGC[] = [];
  cgcs.forEach(cgc => {
    cgc.units?.forEach(unit => {
      if (
        allUnitsFromCGCs.find(unitFromCGCs => unitFromCGCs.unit.label === unit.label) === undefined
      ) {
        allUnitsFromCGCs.push({ unit, cgc: { id: cgc.id, label: cgc.label } });
      }
    });
  });
  const filteredUnitsFromCGCs = allUnitsFromCGCs.filter(unitWithCGC =>
    unitWithCGC.unit.id.includes(keyword.toUpperCase().trim())
  );
  filteredUnitsFromCGCs.sort((unitA, unitB) => (unitA.unit.id < unitB.unit.id ? -1 : 1));
  return filteredUnitsFromCGCs;
};
