import React from 'react';

import moment from 'moment';
import ContentLoader from 'react-content-loader';

import { useFetchAppNews } from 'src/Hooks/HomeEdition/useFetchAppNews';
import { appsConstants } from 'src/Utils/AppsDetailsValues/AppsDetailsValues';

import styles from './Patchnote.module.scss';
import { Props } from './Patchnote.types';

const Patchnote = ({ isProd }: Props) => {
  const { data: appNews, isError, isLoading } = useFetchAppNews();

  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>nouveautés</div>
      {!isLoading && !isError && appNews && (
        <div>
          {appNews.map(oneAppNews => (
            <div key={oneAppNews.id} className={styles.appContainer}>
              <div className={styles.dashContainer}>
                <div className={styles.dash}></div>
              </div>
              <div className={styles.iconContainer}>
                {oneAppNews.app && appsConstants[oneAppNews.app]
                  ? isProd
                    ? appsConstants[oneAppNews.app]?.icon.prod
                    : appsConstants[oneAppNews.app]?.icon.uat
                  : 'application non renseignée'}
              </div>
              <div className={styles.newsContainer}>
                <div className={styles.newsFirstLine}>
                  <div className={styles.appName}>
                    {oneAppNews.app && appsConstants[oneAppNews.app]
                      ? appsConstants[oneAppNews.app]?.fullName
                      : 'application non renseignée'}
                  </div>
                  <div className={styles.version}>
                    - version {oneAppNews.version} - modifié le{' '}
                    {moment(oneAppNews.updatedAt).format('L')}
                  </div>
                </div>
                <div className={styles.newsTitle}>{oneAppNews.newsTitle}</div>
                <div className={styles.newsDescription}>{oneAppNews.description}</div>
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading && (
        <ContentLoader height="12rem" width="100%" uniqueKey="CGCsLoader">
          <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="3rem" />
          <rect x="0" rx="0.5rem" ry="0.5rem" y="4rem" width="100%" height="3rem" />
          <rect x="0" rx="0.5rem" ry="0.5rem" y="8rem" width="100%" height="3rem" />
        </ContentLoader>
      )}
      {isError && <p>Erreur lors de la récupération des nouveautés des applications</p>}
    </div>
  );
};

export default Patchnote;
