import { UseQueryOptions, useQuery } from 'react-query';

import { AgenciesService, AgencyConfigDto } from 'src/Services/API';

export const useGetAgencyConfig = (
  agencyId?: string,
  config?: UseQueryOptions<AgencyConfigDto | undefined, Error>
) => {
  return useQuery<AgencyConfigDto | undefined, Error>(
    ['agency-config', agencyId],
    async () => {
      if (!agencyId) return undefined;
      return await AgenciesService.agenciesControllerGetConfig({ agencyId });
    },
    {
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
