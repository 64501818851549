import React, { useCallback } from 'react';

import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import Styles from './CustomDropdownOption.module.scss';
import Props from './CustomDropdownOption.types';

const CustomDropdownOption = <T,>({ data, isSelected, setValue }: Props<T>) => {
  const selectOption = useCallback(() => setValue(data, 'select-option'), [data, setValue]);

  return (
    <div
      className={classnames(
        Styles.CustomDropdownOption,
        isSelected && Styles.Selected,
        data.className
      )}
      onClick={selectOption}
    >
      {data.icon && <div className={Styles.LeftIcon}>{data.icon}</div>}
      <div className={Styles.Label}>{data.label}</div>
      {isSelected && <Check className={Styles.RightIcon} />}
    </div>
  );
};

export default CustomDropdownOption;
