import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import ContentLoader from 'react-content-loader';

import { HomeImage } from 'src/Assets';
import { useFetchAppDetails } from 'src/Hooks/HomeEdition/useFetchAppDetails';
import { useFetchHomePageConfig } from 'src/Hooks/HomeEdition/useFetchHomePageConfig';
import { HomePageFormat, RandstadAppEnum } from 'src/Services/API';

import { useIsProductionEnvironment } from '../../../Hooks/Environment/useIsProductionEnvironment';

import styles from './Home.module.scss';
import Patchnote from './Patchnote/Patchnote.component';
import RandstadAppsDetails from './RandstadAppsDetails/RandstadAppsDetails.component';

const appsDescriptions: Record<RandstadAppEnum, string> = {
  [RandstadAppEnum.EDP]: '',
  [RandstadAppEnum.FLASH]: '',
  [RandstadAppEnum.RECRUTLIVE]: '',
  [RandstadAppEnum.PUZZLE]: '',
  [RandstadAppEnum.SATISFACTION]: '',
  [RandstadAppEnum.TDP]: '',
  [RandstadAppEnum.PIGE]: '',
  [RandstadAppEnum.SCOPE]: '',
  [RandstadAppEnum.IT]: '',
  [RandstadAppEnum.BDP]: '',
  [RandstadAppEnum.CUSTOM_RANDY]: '',
};

const Home = () => {
  const { isProd } = useIsProductionEnvironment();
  const {
    data: config,
    isLoading: configIsLoading,
    isError: configIsError,
    isIdle: configIsIdle,
  } = useFetchHomePageConfig();
  const {
    data: appDetails,
    isLoading: appDetailsIsLoading,
    isError: appDetailsIsError,
    isIdle: appDetailsIsIdle,
  } = useFetchAppDetails();

  useEffect(() => {
    Object.keys(RandstadAppEnum).forEach(app => {
      appsDescriptions[app as RandstadAppEnum] =
        appDetails?.find(appDetail => appDetail.app === app)?.description ?? '';
    });
  }, [appDetails]);

  const {
    isAuthenticated,
    isLoading: authLoading,
    loginWithRedirect,
    error: authError,
  } = useAuth0();
  useEffect(() => {
    if (!authLoading && !isAuthenticated && !authError) loginWithRedirect();
  }, [authLoading, isAuthenticated, loginWithRedirect, authError]);

  return (
    <div className={styles.container}>
      {(configIsIdle || configIsLoading || configIsError) && (
        <div className={styles.leftSideLoadingContainer}>
          <ContentLoader height="100%" width="100%" uniqueKey="ConfigLoader">
            <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="4rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="5rem" width="100%" height="4rem" />
          </ContentLoader>
        </div>
      )}
      {!(configIsIdle || configIsLoading || configIsError) && (
        <>
          {!config ||
            (![HomePageFormat.SMALL_IMAGE, HomePageFormat.NO_IMAGE].includes(
              config.homePageFormat
            ) && (
              <div className={styles.leftSideBigImageContainer}>
                <img src={HomeImage} className={styles.leftSideBigImageImage} alt="home" />
                <div className={styles.leftSideBigImageText}>{config?.homePageTitle}</div>
              </div>
            ))}
          {config?.homePageFormat === HomePageFormat.SMALL_IMAGE && (
            <div className={styles.leftSideSmallImageContainer}>
              <div className={styles.leftSideSmallImageImageContainer}>
                <img src={HomeImage} className={styles.leftSideSmallImageImage} alt="home" />
                <div className={styles.leftSideSmallImageText}>{config?.homePageTitle}</div>
              </div>
              <div className={styles.leftSideSmallImagePatchnoteContainer}>
                <Patchnote isProd={isProd} />
              </div>
            </div>
          )}
          {config?.homePageFormat === HomePageFormat.NO_IMAGE && (
            <div className={styles.leftSideNoImageContainer}>
              <Patchnote isProd={isProd} />
            </div>
          )}
        </>
      )}
      <div className={styles.randstadAppsDetails}>
        {(appDetailsIsIdle || appDetailsIsLoading || appDetailsIsError) && (
          <ContentLoader height="100%" width="100%" uniqueKey="RandstadAppsDetailsLoader">
            <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="4rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="5rem" width="100%" height="4rem" />
          </ContentLoader>
        )}
        {!(appDetailsIsIdle || appDetailsIsLoading || appDetailsIsError) && (
          <RandstadAppsDetails isProd={isProd} width={'100%'} appDetails={appDetails ?? []} />
        )}
      </div>
    </div>
  );
};

export default Home;
