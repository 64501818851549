import React from 'react';

import Styles from './Filet.module.scss';
import Props from './Filet.types';

const Filet = ({ style }: Props) => {
  return <hr className={Styles.Filet} style={style} />;
};

export default Filet;
