import React, { useState } from 'react';

import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Pencil,
  ThreeDots,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import { EditionAppDetailsMode } from 'src/Containers/Modals/EditAppDetailsModal/EditAppDetailsModal.types';
import { useModalWithLocation } from 'src/Hooks/Navigation';

import styles from './AppDetailsActions.module.scss';
import { Props } from './AppDetailsActions.types';

const AppDetailsActions = ({ appDetail }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const editAppDetailsModal = useModalWithLocation('editAppDetails');
  const deleteAppDetailsModal = useModalWithLocation('deleteAppDetails');
  return (
    <PopupMenu
      position={'left top'}
      width={250}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpen })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        key="editAppDetails"
        icon={<Pencil />}
        text="modifier"
        onClick={() => {
          editAppDetailsModal.open({
            modalParameters: {
              editionAppDetailsMode: EditionAppDetailsMode.MODIFY,
              appDetail,
            },
          });
        }}
      />
      <PopupMenu.Item
        key="deleteAppDetails"
        icon={<Trashcan />}
        text="supprimer"
        onClick={() => {
          deleteAppDetailsModal.open({
            modalParameters: { app: appDetail.app },
          });
        }}
      />
    </PopupMenu>
  );
};

export default AppDetailsActions;
