import { Location } from 'history';

import {
  ADMIN_FIRST_SECTION,
  ADMIN_SECOND_SECTION,
} from 'src/Containers/AdminPage/SideBar/SideBar.types';
import { EditionCGCMode } from 'src/Containers/Modals/CGC/AddCGCToUnitsModal/AddCGCToUnitsModal.types';
import { EditionAppDetailsMode } from 'src/Containers/Modals/EditAppDetailsModal/EditAppDetailsModal.types';
import { EditionAppNewsMode } from 'src/Containers/Modals/EditAppNewsModal/EditAppNewsModal.types';
import { EditionRightMode } from 'src/Containers/Modals/Rights/EditRightModal/EditRightModal.types';

import { AppDetail, AppNews, CGCWithUnits, RandstadAppEnum, Right, UnitaryRight } from '../API';

export interface ModalRoute {
  path: string;
  component: React.FC<{ modalParameters?: unknown }>;
  necessaryUnitaryRights?: UnitaryRight[];
}
export interface LocationState<T = undefined> {
  modalParameters?: T;
  background: Location<unknown>;
}

export function buildUrl({
  firstSection,
  secondSection,
}: {
  firstSection: ADMIN_FIRST_SECTION;
  secondSection: ADMIN_SECOND_SECTION;
}) {
  return `/admin/${firstSection}/${secondSection}`;
}

export interface EditRightState {
  modalParameters?: {
    editionRightMode: EditionRightMode;
    right?: Right | undefined;
  };
  background: Location<unknown>;
}

export interface DeleteRightState {
  modalParameters?: {
    consultantSiid: string;
  };
  background: Location<unknown>;
}

export interface EditCGCState {
  modalParameters?: {
    cgcWithUnits: CGCWithUnits;
  };
  background: Location<unknown>;
}

export interface AddCGCToUnitsState {
  modalParameters?: {
    editionCGCMode: EditionCGCMode;
    units: string[];
    previousCGC?: string;
  };
  background: Location<unknown>;
}

export interface EditAppNewsState {
  modalParameters?: {
    editionAppNewsMode: EditionAppNewsMode;
    isArchived: boolean;
    appNews?: AppNews | undefined;
  };
  background: Location<unknown>;
}

export interface DeleteAppNewsState {
  modalParameters?: {
    isArchived: boolean;
    id: string;
  };
  background: Location<unknown>;
}

export interface EditAppDetailsState {
  modalParameters?: {
    editionAppDetailsMode: EditionAppDetailsMode;
    appDetail?: AppDetail | undefined;
  };
  background: Location<unknown>;
}

export interface DeleteAppDetailsState {
  modalParameters?: {
    app?: RandstadAppEnum;
  };
  background: Location<unknown>;
}

const regCGCSection = new RegExp(
  `^/admin/${ADMIN_FIRST_SECTION.business}/${ADMIN_SECOND_SECTION.cgc}`
);
const regQualificationsSection = new RegExp(
  `^/admin/${ADMIN_FIRST_SECTION.business}/${ADMIN_SECOND_SECTION.qualifications}`
);
const regHomeEditionSection = new RegExp(
  `^/admin/${ADMIN_FIRST_SECTION.apps}/${ADMIN_SECOND_SECTION.home}`
);
const regRightsSection = new RegExp(
  `^/admin/${ADMIN_FIRST_SECTION.parameters}/${ADMIN_SECOND_SECTION.rights}`
);
export const getActiveSection = (url: string): [ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION] => {
  if (url.match(regCGCSection)) {
    return [ADMIN_FIRST_SECTION.business, ADMIN_SECOND_SECTION.cgc];
  }
  if (url.match(regQualificationsSection)) {
    return [ADMIN_FIRST_SECTION.business, ADMIN_SECOND_SECTION.qualifications];
  }
  if (url.match(regHomeEditionSection)) {
    return [ADMIN_FIRST_SECTION.apps, ADMIN_SECOND_SECTION.home];
  }
  if (url.match(regRightsSection)) {
    return [ADMIN_FIRST_SECTION.parameters, ADMIN_SECOND_SECTION.rights];
  }
  return [ADMIN_FIRST_SECTION.business, ADMIN_SECOND_SECTION.cgc];
};

export function setTitleAndIcon(title: string, icon: string) {
  document.title = title;
  const link = document.querySelector('link[rel=icon]') as HTMLLinkElement;
  link.href = `${process.env.PUBLIC_URL}/${icon}`;
}
