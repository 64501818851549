import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { RightsProfile, RightsService } from 'src/Services/API';

import { useAuth } from '../Authentication/useAuth';
import { QueryKeys } from '../types';

export const useFetchAuthorizedModifiedProfiles = (
  extraOptions?: UseQueryOptions<RightsProfile[], AxiosError>
) => {
  const { isAuthenticated } = useAuth();

  return useQuery<RightsProfile[], AxiosError>(
    [QueryKeys.fetchAuthorizedModifiedProfiles],
    async () => {
      return await RightsService.rightsControllerGetAuthorizedModifiedProfiles();
    },
    {
      enabled: isAuthenticated,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
