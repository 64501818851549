import React from 'react';

import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { WhiteCirclePlus } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { EditionRightMode } from 'src/Containers/Modals/Rights/EditRightModal/EditRightModal.types';
import { useModalWithLocation } from 'src/Hooks/Navigation';

const RightsTopBarRightComponent = () => {
  const editRightModal = useModalWithLocation('editRight');
  return (
    <Button.Primary.XSmall
      leftIcon={<WhiteCirclePlus />}
      text="ajouter un collaborateur"
      onClick={() =>
        editRightModal.open({
          modalParameters: { editionRightMode: EditionRightMode.CREATE },
        })
      }
    />
  );
};
export default RightsTopBarRightComponent;
