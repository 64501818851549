import React from 'react';

import {
  Button,
  FetchButton,
  ModalDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import { useDeleteRights } from 'src/Hooks/Rights/useDeleteRights';
import { toFetchStatus } from 'src/Services/Async';
import { DeleteRightState } from 'src/Services/Routing';

const DeleteRightModal = () => {
  const history = useHistory();
  const location = useLocation<DeleteRightState>();
  const modalParameters = location.state.modalParameters;
  const deleteRights = useDeleteRights();

  const onDelete = () => {
    modalParameters &&
      deleteRights.mutate({
        consultantSiid: modalParameters?.consultantSiid,
      });
  };
  const onClose = () => {
    history.push('/admin/parameters/rights');
  };

  return (
    <ModalDeprecated
      onClose={onClose}
      title="Attention"
      open
      icon={<BDPDeleteWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={onClose} text="annuler" />,
        <FetchButton
          title="supprimer"
          onClick={onDelete}
          fetchStatus={toFetchStatus(deleteRights)}
          onSuccess={onClose}
        />,
      ]}
    >
      <div>
        Vous vous apprêtez à supprimer définitivement les droits de ce collaborateur. Confirmez-vous
        ce choix ?
      </div>
    </ModalDeprecated>
  );
};

export default DeleteRightModal;
