import { UseQueryOptions, useQuery } from 'react-query';

import { AgenciesService, Company } from 'src/Services/API';

export const useGetCompanies = (agencyId?: string, config?: UseQueryOptions<Company[], Error>) => {
  return useQuery<Company[], Error>(
    ['companies', agencyId],
    async () => {
      const companies = await AgenciesService.agenciesControllerGetCompanies({
        agencyId: agencyId!,
      });
      companies.sort((a, b) => {
        if (a.companyName > b.companyName) return 1;
        if (a.companyName < b.companyName) return -1;
        if (a.companySiret > b.companySiret) return 1;
        if (a.companySiret < b.companySiret) return -1;
        return 0;
      });
      return companies;
    },
    {
      enabled: Boolean(agencyId),
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
