import { UseQueryOptions, useQuery } from 'react-query';

import { CustomLinksService } from 'src/Services/API';

export const useGetExistingLink = (
  agencyId?: string,
  companyId?: string,
  qualificationId?: string,
  config?: UseQueryOptions<string | undefined, Error>
) => {
  return useQuery<string | undefined, Error>(
    ['link', agencyId, companyId, qualificationId],
    async () => {
      const data = await CustomLinksService.customLinksControllerGetExistingLink({
        body: {
          agencyId: agencyId!,
          companyId: companyId!,
          qualificationId: qualificationId!,
        },
      });
      return data;
    },
    {
      enabled: Boolean(agencyId && companyId && qualificationId),
      refetchOnWindowFocus: false,
      ...config,
    }
  );
};
