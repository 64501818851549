import React from 'react';

import {
  BDP,
  BDPMobile,
  BDPUATMobile,
  CustomRandyMobileIcon,
  CustomRandyProd,
  CustomRandyUATMobile,
  CustomRandyUat,
  EDPMobileIcon,
  EDPProd,
  EDPUATMobile,
  EDPUat,
  FlashMobileIcon,
  FlashProd,
  FlashUATMobile,
  FlashUat,
  ITProd,
  ITUat,
  PigeMobileIcon,
  PigeProd,
  PigeUATMobile,
  PigeUat,
  PuzzleMobileIcon,
  PuzzleProd,
  PuzzleUATMobile,
  PuzzleUat,
  RLMobileIcon,
  RLUATMobile,
  RecrutLiveProd,
  RecrutLiveUat,
  SatisfactionMobile,
  SatisfactionProd,
  SatisfactionUATMobile,
  SatisfactionUat,
  ScopeProd,
  ScopeUat,
  TDPMobileIcon,
  TDPProd,
  TDPUATMobile,
  TDPUat,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { URL } from 'src/Containers/CustomRandy/CustomRandy.types';
import { RandstadAppEnum } from 'src/Services/API';

import styles from './AppsDetailsValues.module.scss';

export enum RANDSTAD_APP_CATEGORY {
  ADMINISTRATIVE = 'administratif',
  PROSPECTION = 'prospection',
  PRODUCTION = 'production',
  PROCEDURE = 'démarche',
  SECTOR = 'secteur',
}

export interface AppConstant {
  icon: {
    prod: React.ReactNode;
    uat: React.ReactNode;
    mobileProd: React.ReactNode;
    mobileUat: React.ReactNode;
  };
  fullName: string;
  category: RANDSTAD_APP_CATEGORY;
  link: { prod: string; uat: string };
  iconStyle: string;
}

export const appsConstants: Record<RandstadAppEnum, AppConstant> = {
  [RandstadAppEnum.EDP]: {
    icon: {
      prod: <EDPProd />,
      uat: <EDPUat />,
      mobileProd: <EDPMobileIcon />,
      mobileUat: <EDPUATMobile />,
    },
    fullName: 'étude de poste',
    category: RANDSTAD_APP_CATEGORY.PRODUCTION,
    link: {
      prod: 'https://etudedeposte.grouperandstad.fr',
      uat: 'https://recette.etudedeposte.grouperandstad.fr',
    },
    iconStyle: styles.navyColor,
  },
  [RandstadAppEnum.FLASH]: {
    icon: {
      prod: <FlashProd />,
      uat: <FlashUat />,
      mobileProd: <FlashMobileIcon />,
      mobileUat: <FlashUATMobile />,
    },
    fullName: 'flash',
    category: RANDSTAD_APP_CATEGORY.ADMINISTRATIVE,
    link: {
      prod: 'https://flash.grouperandstad.fr',
      uat: 'https://recette.flash.grouperandstad.fr',
    },
    iconStyle: styles.warmredColor,
  },
  [RandstadAppEnum.RECRUTLIVE]: {
    icon: {
      prod: <RecrutLiveProd />,
      uat: <RecrutLiveUat />,
      mobileProd: <RLMobileIcon />,
      mobileUat: <RLUATMobile />,
    },
    fullName: "recrut'live",
    category: RANDSTAD_APP_CATEGORY.PROSPECTION,
    link: {
      prod: 'https://recrutlive.grouperandstad.fr',
      uat: 'https://recette.recrutlive.grouperandstad.fr',
    },
    iconStyle: styles.navyColor,
  },
  [RandstadAppEnum.PUZZLE]: {
    icon: {
      prod: <PuzzleProd />,
      uat: <PuzzleUat />,
      mobileProd: <PuzzleMobileIcon />,
      mobileUat: <PuzzleUATMobile />,
    },
    fullName: 'puzzle',
    category: RANDSTAD_APP_CATEGORY.PROSPECTION,
    link: {
      prod: 'https://puzzleweb.grouperandstad.fr',
      uat: 'https://recette.puzzleweb.grouperandstad.fr',
    },
    iconStyle: styles.navyColor,
  },
  [RandstadAppEnum.SATISFACTION]: {
    icon: {
      prod: <SatisfactionProd />,
      uat: <SatisfactionUat />,
      mobileProd: <SatisfactionMobile />,
      mobileUat: <SatisfactionUATMobile />,
    },
    fullName: 'satisfaction',
    category: RANDSTAD_APP_CATEGORY.PROCEDURE,
    link: {
      prod: 'https://satisfaction.grouperandstad.fr',
      uat: 'https://recette.satisfaction.grouperandstad.fr',
    },
    iconStyle: styles.blueColor,
  },
  [RandstadAppEnum.TDP]: {
    icon: {
      prod: <TDPProd />,
      uat: <TDPUat />,
      mobileProd: <TDPMobileIcon />,
      mobileUat: <TDPUATMobile />,
    },
    fullName: 'tour de prod',
    category: RANDSTAD_APP_CATEGORY.PRODUCTION,
    link: {
      prod: 'https://tourdeprod.grouperandstad.fr',
      uat: 'https://recette.tourdeprod.grouperandstad.fr',
    },
    iconStyle: styles.yellowColor,
  },
  [RandstadAppEnum.PIGE]: {
    icon: {
      prod: <PigeProd />,
      uat: <PigeUat />,
      mobileProd: <PigeMobileIcon />,
      mobileUat: <PigeUATMobile />,
    },
    fullName: 'la pige',
    category: RANDSTAD_APP_CATEGORY.SECTOR,
    link: {
      prod: 'https://pige.grouperandstad.fr',
      uat: 'https://recette.pige.grouperandstad.fr',
    },
    iconStyle: styles.blueColor,
  },
  [RandstadAppEnum.BDP]: {
    icon: {
      prod: <BDP />,
      uat: <BDP />,
      mobileProd: <BDPMobile />,
      mobileUat: <BDPUATMobile />,
    },
    fullName: 'brief de poste',
    category: RANDSTAD_APP_CATEGORY.PRODUCTION,
    link: {
      prod: 'https://briefdeposte.grouperandstad.fr',
      uat: 'https://recette.briefdeposte.grouperandstad.fr',
    },
    iconStyle: styles.warmredColor,
  },
  [RandstadAppEnum.SCOPE]: {
    icon: {
      prod: <ScopeProd />,
      uat: <ScopeUat />,
      mobileProd: <ScopeProd />,
      mobileUat: <ScopeUat />,
    },
    fullName: 'scope',
    category: RANDSTAD_APP_CATEGORY.PROSPECTION,
    link: {
      prod: 'https://scoperandstad.page.link/u9DC',
      uat: 'https://scoperandstad.page.link/gnpR',
    },
    iconStyle: styles.greenishColor,
  },
  [RandstadAppEnum.IT]: {
    icon: { prod: <ITProd />, uat: <ITUat />, mobileProd: <ITProd />, mobileUat: <ITUat /> },
    fullName: 'aide IT',
    category: RANDSTAD_APP_CATEGORY.SECTOR,
    link: {
      prod: 'https://randstad.service-now.com/',
      uat: 'https://randstad.service-now.com/',
    },
    iconStyle: styles.navyColor,
  },
  [RandstadAppEnum.CUSTOM_RANDY]: {
    icon: {
      prod: <CustomRandyProd />,
      uat: <CustomRandyUat />,
      mobileProd: <CustomRandyMobileIcon />,
      mobileUat: <CustomRandyUATMobile />,
    },
    fullName: 'randy personnalisé',
    category: RANDSTAD_APP_CATEGORY.SECTOR,
    link: {
      prod: `/${URL}`,
      uat: `/${URL}`,
    },
    iconStyle: styles.blueColor,
  },
};
