import React from 'react';

import { Button, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './EditCGCModalContent.module.scss';
import { Props } from './EditCGCModalContent.types';

const EditCGCModalContent = ({ cgcLabel, cgcNewUnits, setCGCNewUnits }: Props) => {
  return (
    <>
      <WithLightTitle title="nom du CGC">
        <div>{cgcLabel}</div>
      </WithLightTitle>
      {cgcNewUnits.length === 0 ? (
        <div className={styles.unitsContainer}>Aucune unité associée</div>
      ) : (
        <WithLightTitle
          className={styles.unitsContainer}
          title="liste des unités associées"
          rightTitleComponent={
            <div className={styles.unitsCount}>{pluralFormat(cgcNewUnits.length, 'unité')}</div>
          }
        >
          {cgcNewUnits.map(cgcUnit => {
            return (
              <div key={cgcUnit.id}>
                <div className={styles.separator} />
                <div className={styles.UnitCard}>
                  <div>
                    <div className={styles.UnitId}>{cgcUnit.id}</div>
                    <div className={styles.UnitLabel}>{cgcUnit.label}</div>
                  </div>
                  <div>
                    <Button.Tertiary className={styles.UnitDeleteButton}>
                      <Trashcan
                        onClick={() => {
                          setCGCNewUnits(cgcNewUnits.filter(unit => unit.id !== cgcUnit.id));
                        }}
                      />
                    </Button.Tertiary>
                  </div>
                </div>
              </div>
            );
          })}
        </WithLightTitle>
      )}
    </>
  );
};

export default EditCGCModalContent;
