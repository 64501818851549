import {
  ADMIN_FIRST_SECTION,
  ADMIN_SECOND_SECTION,
} from 'src/Containers/AdminPage/SideBar/SideBar.types';
import { UnitaryRight } from 'src/Services/API';
import { security } from 'src/Services/API/Interceptors';

export interface AuthorizedPage {
  firstSection: ADMIN_FIRST_SECTION;
  secondSection: ADMIN_SECOND_SECTION;
}

const adminPages = [
  {
    firstSection: ADMIN_FIRST_SECTION.business,
    secondSection: ADMIN_SECOND_SECTION.cgc,
    necessaryUnitaryRights: [UnitaryRight.EDIT_CGC],
  },
  {
    firstSection: ADMIN_FIRST_SECTION.business,
    secondSection: ADMIN_SECOND_SECTION.qualifications,
    necessaryUnitaryRights: [UnitaryRight.EDIT_QUALIFICATIONS],
  },
  {
    firstSection: ADMIN_FIRST_SECTION.apps,
    secondSection: ADMIN_SECOND_SECTION.home,
    necessaryUnitaryRights: [UnitaryRight.EDIT_HOME_EDITION],
  },
  {
    firstSection: ADMIN_FIRST_SECTION.parameters,
    secondSection: ADMIN_SECOND_SECTION.rights,
    necessaryUnitaryRights: [
      UnitaryRight.ADD_ADMIN,
      UnitaryRight.ADD_ADMIN_CGC,
      UnitaryRight.ADD_ADMIN_HOME_EDITION,
    ],
  },
];

export const useFetchAuthorizedPages = () => {
  const userUnitaryRights = security.getUserUnitaryRights();
  return adminPages
    .filter(
      page =>
        userUnitaryRights?.find(
          userUnitaryRight => page.necessaryUnitaryRights?.includes(userUnitaryRight)
        ) !== undefined
    )
    .map(page => ({
      firstSection: page.firstSection,
      secondSection: page.secondSection,
    })) as AuthorizedPage[];
};
