import React, { useEffect } from 'react';

import { Route, Router, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { LocationState, setTitleAndIcon } from 'src/Services/Routing';
import history from 'src/Utils/history';

import Home from './Home';
import styles from './MainPage.module.scss';
import TopBar from './TopBar';

const MainPage = () => {
  useEffect(() => {
    setTitleAndIcon('Portail Mobilité', 'logo192.png');
  });

  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  return (
    <div className={styles.container}>
      <TopBar />
      <Router history={history}>
        <Switch location={background || location}>
          <Route exact path={match.path} component={Home} />
        </Switch>
      </Router>
    </div>
  );
};

export default MainPage;
