import React, { useCallback, useState } from 'react';

import {
  Button,
  HorizontalCardWithTitle,
  Loader,
  ToggleSwitch,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PlusCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import BackButton from 'src/Components/BackButton';
import ErrorMessage from 'src/Components/ErrorMessage';
import Filet from 'src/Components/Filet';
import InputLabel from 'src/Components/InputLabel';
import { useGetAgencyCalendars } from 'src/Hooks/CustomRandy/useGetAgencyCalendars';
import { useGetAgencyConfig } from 'src/Hooks/CustomRandy/useGetAgencyConfig';
import { useUpdateAgencyConfig } from 'src/Hooks/CustomRandy/useUpdateAgencyConfig';
import { AgencyConfigDto } from 'src/Services/API';
import history from 'src/Utils/history';

import {
  AGENDA_HELP_LINK,
  CustomRandySection,
  CustomRandySubSection,
  URL,
} from '../../CustomRandy.types';

import Styles from './AgencyConfigScreen.module.scss';

const AgencyConfigScreen = () => {
  const { agencyId } = useParams<{ agencyId: string }>();
  const { data, isLoading, error } = useGetAgencyConfig(agencyId, {
    onSuccess: data => {
      setCoordsEnabled(Boolean(data?.coords && (data.coords.email || data.coords.phoneNumber)));
    },
  });
  const { data: calendars } = useGetAgencyCalendars(agencyId);
  const { mutate, isLoading: isUpdating } = useUpdateAgencyConfig({
    onSuccess: (_, { body }) => {
      const values = {
        email: body.coords?.email ?? '',
        phoneNumber: body.coords?.phoneNumber ?? '',
      };
      reset(values);
      toast.success('configuration enregistrée');
    },
    onError: err => {
      switch (err.cause) {
        case 'PRIVILEGES':
          toast.error("l'utilisateur n'a pas de droits en écriture sur ce calendrier");
          break;
        case 'BAD_EMAIL':
          toast.error('utilisateur inconnu');
          break;
        case 'BAD_CALENDAR':
          toast.error('identifiant de calendrier inconnu');
          break;
        case 'UNKNOWN':
        default:
          toast.error(`erreur lors de l'enregistrement: ${err.message}`);
      }
    },
  });

  const [coordsEnabled, setCoordsEnabled] = useState<boolean>();

  const schema = z.object({
    email: z
      .optional(z.string().email('ceci ne ressemble pas à une adresse email'))
      .or(z.literal('')),
    phoneNumber: z
      .optional(
        z.string().refine(phoneNumber => {
          return /^(\+?33|0|0033)[1-9]\d{8}$/.test(phoneNumber.replaceAll(/\s/g, ''));
        }, 'ceci ne ressemble pas à un numéro de téléphone français')
      )
      .or(z.literal('')),
  });

  const { control, handleSubmit, setValue, formState, reset } = useFormWithZodResolver({
    schema,
  });

  const saveConfig = useCallback(
    (values: z.infer<typeof schema>) => {
      const email = values.email?.length ? values.email : undefined;
      let phoneNumber;
      if (values.phoneNumber) {
        phoneNumber = values.phoneNumber
          .replaceAll(/\s/g, '')
          .replace(/^(\+?33|0|0033)([1-9]\d{8})$/, '$2')
          .replace(/^(\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, '+33 $1 $2 $3 $4 $5');
        setValue('phoneNumber', phoneNumber);
      }
      const coords = coordsEnabled ? { email, phoneNumber } : undefined;
      const config: AgencyConfigDto = { coords };
      mutate({ agencyId, body: config });
    },
    [mutate, setValue, agencyId, coordsEnabled]
  );

  return (
    <div className={Styles.AgencyConfigScreen}>
      <BackButton
        text="retour"
        onClick={() =>
          history.push(`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}`)
        }
      />
      <h2 className={Styles.Title}>agence {agencyId}</h2>
      {isLoading && <Loader heightInRem={2} darkMode />}
      {error && <ErrorMessage error={error} />}
      {data && (
        <form className={Styles.ContactForm} onSubmit={handleSubmit(saveConfig)}>
          <Filet />

          <div className={Styles.ToggleContainer}>
            <div>
              <ToggleSwitch checked={coordsEnabled} onCheckStatusChange={setCoordsEnabled} />
            </div>

            <p className={Styles.Paragraph}>
              Voulez-vous communiquer les coordonnées de l'agence au candidat en cas de besoin ?
            </p>
          </div>

          {coordsEnabled && (
            <>
              <InputLabel label="email de l'agence">
                <TextInput
                  name="email"
                  placeholder="email"
                  defaultValue={data.coords?.email ?? ''}
                  control={control}
                  required={false}
                />
              </InputLabel>

              <InputLabel label="téléphone de l'agence">
                <TextInput
                  name="phoneNumber"
                  placeholder="téléphone"
                  defaultValue={data.coords?.phoneNumber ?? ''}
                  control={control}
                  required={false}
                />
              </InputLabel>
            </>
          )}

          <Button
            type="submit"
            text="enregistrer"
            disabled={!formState.isValid}
            rightIcon={isUpdating ? <Loader heightInRem={2} /> : undefined}
          />
        </form>
      )}

      <Filet />

      <div>
        <p className={Styles.Paragraph}>
          Voulez-vous proposer des dates de session aux candidats présélectionnés ?
        </p>

        <a href={AGENDA_HELP_LINK} target="_blank" className={Styles.Link} rel="noreferrer">
          guide de paramétrage de l'agenda
        </a>
      </div>

      {!calendars ? (
        <Loader heightInRem={2} darkMode />
      ) : (
        <>
          {calendars.length > 0 && <p className={Styles.Paragraph}>liste des calendriers:</p>}
          <div className={Styles.CalendarList}>
            {calendars.map(calendar => (
              <HorizontalCardWithTitle
                key={calendar.id}
                title={calendar.title}
                subtitles={[calendar.calendarIdentification, calendar.delegatedEmail]}
                onClick={() => {
                  history.push(
                    `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/${agencyId}/${calendar.id}`
                  );
                }}
              />
            ))}
            <Button.Primary.Medium
              text="ajouter un calendrier"
              rightIcon={<PlusCircle />}
              onClick={() => {
                history.push(
                  `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/${agencyId}/add-calendar`
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AgencyConfigScreen;
