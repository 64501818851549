import React from 'react';

import { WhiteDash } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { animated, useSpring } from 'react-spring';

import Styles from './MenuSectionTitle.module.scss';
import Props from './MenuSectionTitle.types';

const MenuSectionTitle = ({ title, opened }: Props) => {
  const fadeOut = useSpring({
    opacity: opened ? 1 : 0,
  });
  const fadeIn = useSpring({
    opacity: opened ? 0 : 1,
  });

  return (
    <div className={Styles.MenuSectionTitle} key={title}>
      <animated.div style={fadeIn}>
        <WhiteDash className={Styles.WhiteDash} />
      </animated.div>
      <animated.div style={fadeOut}>
        <div className={Styles.Title}>{title}</div>
      </animated.div>
    </div>
  );
};

export default MenuSectionTitle;
