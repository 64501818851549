import React from 'react';

import {
  Button,
  FetchButton,
  ModalDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import { useDeleteAppNews } from 'src/Hooks/HomeEdition/useDeleteAppNews';
import { toFetchStatus } from 'src/Services/Async';
import { DeleteAppNewsState } from 'src/Services/Routing';

import styles from './DeleteAppNewsModal.module.scss';

const DeleteAppNewsModal = () => {
  const history = useHistory();
  const location = useLocation<DeleteAppNewsState>();
  const modalParameters = location.state.modalParameters;
  const deleteAppNews = useDeleteAppNews();

  const onDelete = () => {
    modalParameters &&
      deleteAppNews.mutate({
        isArchived: modalParameters.isArchived,
        appNewsId: modalParameters?.id,
      });
  };
  const onClose = () => {
    history.push('/admin/apps/home');
  };

  return (
    <ModalDeprecated
      onClose={onClose}
      title="Attention"
      open
      icon={<BDPDeleteWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={onClose} text="annuler" />,
        <FetchButton
          className={styles.deleteButton}
          title="supprimer"
          onClick={onDelete}
          fetchStatus={toFetchStatus(deleteAppNews)}
          onSuccess={onClose}
        />,
      ]}
    >
      <div>
        Vous vous apprêtez à supprimer définitivement la nouveauté sur cette application.
        Confirmez-vous ce choix ?
      </div>
    </ModalDeprecated>
  );
};

export default DeleteAppNewsModal;
