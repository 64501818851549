import React, { useState } from 'react';

import {
  Button,
  FetchButton,
  ModalDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';
import { FlecheBack } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ListIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';

import RightsDetails from 'src/Containers/AdminPage/RightsSection/RightsDetails';
import { useAddRights } from 'src/Hooks/Rights/useAddRights';
import { useUpdateRights } from 'src/Hooks/Rights/useUpdateRights';
import { RightsProfile } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { EditRightState } from 'src/Services/Routing';

import styles from './EditRightModal.module.scss';
import { EditionRightMode } from './EditRightModal.types';
import EditRightModalContent from './EditRightModalContent';

const title = {
  [EditionRightMode.CREATE]: "gestion des droits d'un nouveau collaborateur",
  [EditionRightMode.MODIFY]: "modification des droits d'un collaborateur",
};

const hook = {
  [EditionRightMode.CREATE]: useAddRights,
  [EditionRightMode.MODIFY]: useUpdateRights,
};

const EditRightModal = () => {
  const history = useHistory();
  const location = useLocation<EditRightState>();
  const modalParameters = location.state.modalParameters;
  const editionRightMode = modalParameters?.editionRightMode ?? EditionRightMode.CREATE;
  const right = modalParameters?.right;
  const [consultantEmail, setConsultantEmail] = useState<string>('');
  const [selectedProfile, setSelectedProfile] = useState<RightsProfile | undefined>(
    editionRightMode === EditionRightMode.MODIFY ? right?.profile : undefined
  );
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const editRights = hook[editionRightMode]();
  const onClose = () => {
    history.push('/admin/parameters/rights');
  };

  return (
    <ModalDeprecated
      open
      icon={
        !showDetails ? (
          <ListIcon />
        ) : (
          <Button.Tertiary.XSmall
            className={styles.goBackArrow}
            onClick={() => setShowDetails(false)}
          >
            <FlecheBack />
          </Button.Tertiary.XSmall>
        )
      }
      title={!showDetails ? title[editionRightMode] : 'les différents profils'}
      footerActionsLeft={
        !showDetails
          ? [
              <Button.Tertiary
                onClick={() => {
                  setConsultantEmail('');
                  setSelectedProfile(right?.profile ?? undefined);
                }}
              >
                réinitialiser
              </Button.Tertiary>,
            ]
          : []
      }
      footerActionsRight={
        !showDetails
          ? [
              <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
              <FetchButton
                title="valider"
                disabled={
                  editionRightMode === EditionRightMode.CREATE
                    ? !(consultantEmail?.length > 0) || selectedProfile === undefined
                    : selectedProfile === right?.profile
                }
                onClick={() =>
                  editRights.mutate({
                    consultantEmail: consultantEmail,
                    consultantSiid: right?.consultantSiid ?? '',
                    // @ts-ignore
                    profile: selectedProfile,
                  })
                }
                fetchStatus={toFetchStatus(editRights)}
                onSuccess={onClose}
              />,
            ]
          : []
      }
      onClose={onClose}
    >
      {!showDetails ? (
        <EditRightModalContent
          editionRightMode={editionRightMode}
          right={right}
          consultantEmail={consultantEmail}
          setConsultantEmail={setConsultantEmail}
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
          setShowDetails={setShowDetails}
        />
      ) : (
        <RightsDetails />
      )}
    </ModalDeprecated>
  );
};

export default EditRightModal;
