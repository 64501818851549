import React from 'react';

import { Table } from '@randstad-lean-mobile-factory/react-components-core';

import { useFetchProfilesRights } from 'src/Hooks/Rights/useFetchProfilesRights';
import { RightsProfile, UnitaryRight } from 'src/Services/API';

import { rightProfileToString, unitaryRightsToLongString } from '../RightsSection.types';

import styles from './RightsDetails.module.scss';

const RightsDetails = () => {
  const { data: profilesRights } = useFetchProfilesRights();
  const headers = Object.keys(RightsProfile).map(profile => ({
    label: rightProfileToString[profile as RightsProfile],
  }));
  return (
    <Table
      className={styles.profilesTable}
      headers={[{ label: 'actions' }, ...headers]}
      data={Object.keys(UnitaryRight).map(right => [
        <p className={styles.unitaryRight}>{unitaryRightsToLongString[right as UnitaryRight]}</p>,
        ...Object.keys(RightsProfile).map(profile =>
          profilesRights &&
          profilesRights
            .find(profileRights => profileRights.profile === (profile as RightsProfile))
            ?.unitaryRights.includes(right as UnitaryRight) ? (
            <div className={styles.dotContainer}>
              <span className={styles.dot} />
            </div>
          ) : (
            <span />
          )
        ),
      ])}
    />
  );
};

export default RightsDetails;
