import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { RightsService } from 'src/Services/API';

import { MutationKeys, QueryKeys } from '../types';

export const useDeleteRights = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateRights],
    async ({ consultantSiid }: { consultantSiid: string }) => {
      return await RightsService.rightsControllerDeleteRights({ consultantSiid });
    },
    {
      onSuccess: () => {
        toast.success('les droits du collaborateur ont été supprimés');
        queryClient.invalidateQueries(QueryKeys.fetchRights);
        queryClient.invalidateQueries(QueryKeys.fetchUserUnitaryRights);
        queryClient.invalidateQueries(QueryKeys.fetchAuthorizedModifiedProfiles);
      },
      onError: (error: AxiosError) => {
        switch (error.response?.status) {
          case 403:
            toast.error('droits insuffisants pour supprimer les droits de ce collaborateur');
            break;
          case 409:
            toast.error('vous ne pouvez pas supprimer le dernier superadmin');
            break;
          default:
            toast.error('erreur lors de la suppression des droits');
        }
      },
    }
  );
};
