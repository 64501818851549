import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { AppNews, HomeEditionService } from 'src/Services/API';

import { useAuth } from '../Authentication/useAuth';
import { QueryKeys } from '../types';

export const useFetchAppNews = (extraOptions?: UseQueryOptions<AppNews[], AxiosError>) => {
  const { isAuthenticated } = useAuth();

  return useQuery<AppNews[], AxiosError>(
    [QueryKeys.fetchAppNews],
    async () => {
      return await HomeEditionService.homeEditionControllerGetAppNews();
    },
    {
      onError: () => {
        toast.error('erreur lors de la récupération des nouveautés sur les applications');
      },
      enabled: isAuthenticated,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
