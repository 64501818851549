import React, { useEffect, useState } from 'react';

import {
  SelectionIconButton,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';

import {
  HomePageBigImageFormat,
  HomePageNoImageFormat,
  HomePageSmallImageFormat,
} from 'src/Assets';
import { useFetchHomePageConfig } from 'src/Hooks/HomeEdition/useFetchHomePageConfig';
import { useUpdateHomePageConfig } from 'src/Hooks/HomeEdition/useUpdateHomePageConfig';
import { HomePageFormat } from 'src/Services/API';

import styles from './HomePageConfigEdition.module.scss';

const homePageFormatDetails = {
  [HomePageFormat.BIG_IMAGE]: {
    label: 'grand visuel',
    icon: <HomePageBigImageFormat />,
  },
  [HomePageFormat.SMALL_IMAGE]: {
    label: 'petit visuel',
    icon: <HomePageSmallImageFormat />,
  },
  [HomePageFormat.NO_IMAGE]: {
    label: 'sans visuel',
    icon: <HomePageNoImageFormat />,
  },
};

const HomePageConfigEdition = () => {
  const { data: homePageConfig } = useFetchHomePageConfig();
  const updateHomePageConfigMutation = useUpdateHomePageConfig();

  const [homePageTitle, setHomePageTitle] = useState('');

  useEffect(() => {
    setHomePageTitle(homePageConfig?.homePageTitle ?? '');
  }, [homePageConfig?.homePageTitle]);
  return (
    <div>
      <div className={styles.title}>format</div>
      <WithLightTitle
        title="choisissez parmi les différents types de mise en page"
        className={styles.formatSelectionContainer}
      >
        <div className={styles.buttonsContainer}>
          {Object.values(HomePageFormat).map(format => (
            <SelectionIconButton
              className={styles.button}
              {...homePageFormatDetails[format]}
              selected={homePageConfig?.homePageFormat === format}
              onClick={() =>
                homePageConfig?.homePageFormat !== format &&
                updateHomePageConfigMutation.mutate({
                  homePageFormat: format,
                  homePageTitle: homePageConfig?.homePageTitle ?? '',
                })
              }
            />
          ))}
        </div>
      </WithLightTitle>
      <WithLightTitle title="texte inclus dans le visuel">
        <TextInput
          value={homePageTitle}
          maxLength={50}
          onChange={event => setHomePageTitle((event.target as HTMLInputElement).value)}
          onBlurCapture={() => {
            updateHomePageConfigMutation.mutate({
              homePageFormat: homePageConfig?.homePageFormat ?? HomePageFormat.BIG_IMAGE,
              homePageTitle: homePageTitle,
            });
          }}
        />
      </WithLightTitle>
    </div>
  );
};

export default HomePageConfigEdition;
