import React from 'react';

import {
  AttacheCaseGrey,
  AttacheCaseWhite,
  LinkGrey,
  LinkWhite,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { MenuSection } from 'src/Components/MenuSection/MenuSection.types';

export const URL = 'custom-randy';
export const ICON = 'logoRandy192.png';
export const AGENDA_HELP_LINK =
  'https://docs.google.com/document/d/1KnwuP9OYVviJDkOxv1wslmDNteVFbd98UvIiQIuDjP8';

export enum CustomRandySection {
  links = 'links',
}

export enum CustomRandySubSection {
  links = 'links',
  agency = 'agency',
}

export const menuItems: MenuSection<[CustomRandySection, CustomRandySubSection]>[] = [
  {
    title: 'liens personnalisés',
    item: CustomRandySection.links,
    items: [
      {
        title: 'liens',
        item: CustomRandySubSection.links,
        iconSelected: <LinkWhite />,
        iconNotSelected: <LinkGrey />,
      },
      {
        title: 'agence',
        item: CustomRandySubSection.agency,
        iconSelected: <AttacheCaseWhite />,
        iconNotSelected: <AttacheCaseGrey />,
      },
    ],
  },
];
