import React from 'react';

import Styles from './ErrorMessage.module.scss';
import Props from './ErrorMessage.types';

const ErrorMessage = ({ error }: Props) => {
  return (
    <div className={Styles.ErrorMessage}>{error instanceof Error ? error.message : error}</div>
  );
};

export default ErrorMessage;
