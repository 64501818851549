import React from 'react';

import { Route, Router, Switch, useLocation } from 'react-router-dom';

import { LocationState } from 'src/Services/Routing';
import history from 'src/Utils/history';

import { CustomRandySection, CustomRandySubSection, URL } from '../CustomRandy.types';

import CreateCustomLinkScreen from './CreateCustomLinkScreen';
import InspectCustomLinkScreen from './InspectCustomLinkScreen';
import ListCustomLinksScreen from './ListCustomLinksScreen';

const Links = () => {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  return (
    <Router history={history}>
      <Switch location={background || location}>
        <Route
          exact
          path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}`}
          component={ListCustomLinksScreen}
        />
        <Route
          exact
          path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}/add`}
          component={CreateCustomLinkScreen}
        />
        <Route
          exact
          path={`/${URL}/${CustomRandySection.links}/${CustomRandySubSection.links}/:link`}
          component={InspectCustomLinkScreen}
        />
      </Switch>
    </Router>
  );
};

export default Links;
