import React from 'react';

import { useLocation } from 'react-router-dom';

import { LocationState, getActiveSection } from 'src/Services/Routing';

import { ADMIN_SECOND_SECTION } from '../SideBar/SideBar.types';

import RightsTopBarRightComponent from './RightsTopBarRightComponent';
import styles from './TopBar.module.scss';
import TopBarTitles from './TopBarTitles';

const getTopBarLeftComponent = (section: ADMIN_SECOND_SECTION): JSX.Element | null => {
  switch (section) {
    case ADMIN_SECOND_SECTION.cgc:
      return (
        <TopBarTitles
          title="unités et CGC"
          subtitle="trouvez comment sont réparties les unités entre les différents CGC"
        />
      );
    case ADMIN_SECOND_SECTION.qualifications:
      return (
        <TopBarTitles
          title="qualifications"
          subtitle="retrouvez la liste des qualifications et leurs compétences associées, ajoutez de nouvelles qualifications si nécessaire"
        />
      );
    case ADMIN_SECOND_SECTION.home:
      return (
        <TopBarTitles
          title="informations apps"
          subtitle="gérez les différentes informations de la page du launcher"
        />
      );
    case ADMIN_SECOND_SECTION.rights:
      return (
        <TopBarTitles
          title="gestion des droits utilisateurs"
          subtitle="attribuez les droits d'utilisation suivant les collaborateurs"
        />
      );
    default:
      return (
        <TopBarTitles
          title="unités et CGC"
          subtitle="trouvez comment sont réparties les unités entre les différents CGC"
        />
      );
  }
};

const getTopBarRightComponent = (section: ADMIN_SECOND_SECTION): JSX.Element | null => {
  switch (section) {
    case ADMIN_SECOND_SECTION.cgc:
      return <></>;
    case ADMIN_SECOND_SECTION.qualifications:
      return <></>;
    case ADMIN_SECOND_SECTION.home:
      return null;
    case ADMIN_SECOND_SECTION.rights:
      return <RightsTopBarRightComponent />;
  }
};

const TopBar = () => {
  const location = useLocation<LocationState>();
  const activeSection = getActiveSection(location.pathname);
  const leftComponent = getTopBarLeftComponent(activeSection[1]);
  const rightComponent = getTopBarRightComponent(activeSection[1]);

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {leftComponent}
        {rightComponent}
      </div>
      <div className={styles.separator} />
    </div>
  );
};
export default TopBar;
