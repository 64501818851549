import React, { useState } from 'react';

import { TextInput } from '@randstad-lean-mobile-factory/react-components-core';
import ContentLoader from 'react-content-loader';

import { NoRights } from 'src/Assets';
import { useFetchProfilesRights } from 'src/Hooks/Rights/useFetchProfilesRights';
import { useFetchRights } from 'src/Hooks/Rights/useFetchRights';
import { RightsProfile } from 'src/Services/API';

import TopBar from '../TopBar';

import RightActions from './RightActions';
import styles from './RightsSection.module.scss';
import { rightProfileToString, unitaryRightsToString } from './RightsSection.types';

const RightsSection = () => {
  const { data: allConsultantsRights, isError, isLoading } = useFetchRights();
  const { data: profilesRights } = useFetchProfilesRights();
  const [keyword, setKeyword] = useState<string>('');
  return (
    <>
      <TopBar />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>collaborateurs gérés</div>
          {allConsultantsRights?.length === 0 ? (
            <div>
              aucun collaborateur ne possède de droits, ajoutez un nouveau collaborateur
              <NoRights />
            </div>
          ) : (
            <>
              <div className={styles.rightsSearchContainer}>
                <div className={styles.rightsSearchTitle}>
                  trouvez parmi les collaborateurs auxquels des droits sont attribués
                </div>
                <TextInput
                  className={styles.rightsSearch}
                  value={keyword}
                  placeholder="ex : jean.dupont@randstad.fr"
                  onChange={event => setKeyword((event.target as HTMLInputElement).value)}
                />
              </div>
              {allConsultantsRights
                ?.filter(consultant => consultant.consultantEmail?.startsWith(keyword))
                ?.map(consultantRight => (
                  <div key={`ConsultantRight${consultantRight.consultantSiid}`}>
                    <div className={styles.separator} />
                    <div className={styles.consultantCard}>
                      <div className={styles.consultantCardText}>
                        <div className={styles.consultantName}>
                          {consultantRight.consultantFirstName} {consultantRight.consultantName} (
                          {keyword.length > 0 ? (
                            <>
                              <span className={styles.highlightedPartOfMail}>{keyword}</span>
                              {consultantRight.consultantEmail?.slice(keyword.length)}
                            </>
                          ) : (
                            consultantRight.consultantEmail
                          )}
                          )
                        </div>
                        <div className={styles.consultantProfile}>
                          {`${rightProfileToString[consultantRight.profile]} : `}
                          {profilesRights
                            ?.find(
                              profileRights =>
                                profileRights.profile.toString() ===
                                consultantRight.profile.toString()
                            )
                            ?.unitaryRights.map(unitaryRight => unitaryRightsToString[unitaryRight])
                            .join(', ')}
                        </div>
                      </div>
                      {(consultantRight.profile !== RightsProfile.SUPERADMIN ||
                        allConsultantsRights?.filter(
                          right => right.profile === RightsProfile.SUPERADMIN
                        ).length > 1) && <RightActions right={consultantRight} />}
                    </div>
                  </div>
                ))}
            </>
          )}
          {isLoading && (
            <ContentLoader height="100%" width="100%" uniqueKey="RightsLoader">
              <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="4rem" />
              <rect x="0" rx="0.5rem" ry="0.5rem" y="5rem" width="100%" height="4rem" />
              <rect x="0" rx="0.5rem" ry="0.5rem" y="10rem" width="100%" height="4rem" />
            </ContentLoader>
          )}
          {isError && <p>Erreur lors de la récupération des droits</p>}
        </div>
      </div>
    </>
  );
};
export default RightsSection;
