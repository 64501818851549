import React from 'react';

import { useFetchAllAppNews } from 'src/Hooks/HomeEdition/useFetchAllAppNews';

import styles from './AppNewsEdition.module.scss';
import AppNewsList from './AppNewsList/AppNewsList.component';

const AppNewsEdition = () => {
  const { data: allAppNews, isError, isLoading } = useFetchAllAppNews();
  return (
    <div className={styles.container}>
      <AppNewsList
        isArchived={false}
        appNewsList={allAppNews?.notArchived ?? []}
        isError={isError}
        isLoading={isLoading}
      />
      {allAppNews?.archived && allAppNews?.archived.length > 0 && (
        <>
          <div className={styles.sectionSeparator} />
          <AppNewsList
            isArchived={true}
            appNewsList={allAppNews?.archived ?? []}
            isError={isError}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export default AppNewsEdition;
