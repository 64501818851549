import React, { useMemo, useState } from 'react';

import {
  HorizontalCardWithTitle,
  Loader,
  TextInput as RawTextInput,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Cross, Search } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import ErrorMessage from 'src/Components/ErrorMessage';
import Filet from 'src/Components/Filet';
import InputLabel from 'src/Components/InputLabel';
import { useGetAgencyList } from 'src/Hooks/CustomRandy/useGetAgencyList';
import history from 'src/Utils/history';

import { CustomRandySection, CustomRandySubSection, URL } from '../../CustomRandy.types';

import Styles from './AgencyListScreen.module.scss';

const AgencyListScreen = () => {
  const { data, isLoading, error } = useGetAgencyList();

  const [filterQuery, setFilterQuery] = useState('');

  const filteredData = useMemo(
    () =>
      data?.agencies.filter(agency =>
        agency
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^A-Za-z0-9 ]/g, '')
          .toUpperCase()
          .includes(
            filterQuery
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[^A-Za-z0-9 ]/g, '')
              .toUpperCase()
          )
      ) ?? [],
    [data, filterQuery]
  );

  return (
    <div className={Styles.AgencyListScreen}>
      <h2 className={Styles.Title}>mes agences</h2>
      {isLoading && <Loader heightInRem={2} darkMode />}
      {error && <ErrorMessage error={error} />}
      {data && !data.agencies.length && "vous n'êtes rattaché à aucune agence"}
      {data && data.agencies.length > 0 && (
        <>
          <div className={Styles.Filters}>
            <InputLabel label="trouver une agence">
              <RawTextInput
                placeholder="ex: MGL"
                rightIcon={filterQuery ? <Cross className={Styles.Cross} /> : undefined}
                value={filterQuery}
                onChange={event => {
                  setFilterQuery(event.currentTarget.value);
                }}
                onRightIconClick={() => {
                  setFilterQuery('');
                }}
                leftIcon={<Search className={Styles.Icon} />}
              />
            </InputLabel>
          </div>

          <Filet />

          <div>
            {!filteredData.length && `aucune de vos agences ne correspond à « ${filterQuery} »`}
            {filteredData.map(agency => (
              <HorizontalCardWithTitle
                key={agency}
                title={`agence ${agency}`}
                className={Styles.AgencyCard}
                onClick={() => {
                  history.push(
                    `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/${agency}`
                  );
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AgencyListScreen;
