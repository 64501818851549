import React from 'react';

import {
  DropDown,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';

import { RandstadAppEnum } from 'src/Services/API';
import { appsConstants } from 'src/Utils/AppsDetailsValues/AppsDetailsValues';

import styles from './EditAppNewsModalContent.module.scss';
import { Props } from './EditAppNewsModalContent.types';

const EditAppNewsModalContent = ({
  app,
  version,
  newsTitle,
  description,
  setApp,
  setVersion,
  setNewsTitle,
  setDescription,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <WithLightTitle title="application concernée" className={styles.rowChildren}>
          <DropDown
            items={Object.values(RandstadAppEnum)}
            selectedItem={app}
            placeholder="sélectionnez"
            keyValueExtractor={(searchResult: RandstadAppEnum) => ({
              key: searchResult,
              value: appsConstants[searchResult].fullName ?? 'application non renseignée',
            })}
            onSelectItem={(item?: RandstadAppEnum) => setApp(item)}
          />
        </WithLightTitle>
        <WithLightTitle title="version" className={styles.rowChildren}>
          <TextInput
            value={version}
            placeholder="ex : 5.1.2"
            onChange={event => setVersion((event.target as HTMLInputElement).value)}
          />
        </WithLightTitle>
      </div>
      <WithLightTitle title="titre">
        <TextInput
          value={newsTitle}
          placeholder="ex : des nouveautés incroyables"
          onChange={event => setNewsTitle((event.target as HTMLInputElement).value)}
        />
      </WithLightTitle>
      <WithLightTitle title="texte descriptif" className={styles.descriptionContainer}>
        <TextArea
          className={styles.descriptionContainer}
          value={description}
          placeholder="décrivez la nouveauté ici"
          onChange={event => setDescription((event.target as HTMLInputElement).value)}
        />
      </WithLightTitle>
    </div>
  );
};

export default EditAppNewsModalContent;
