import React from 'react';

import {
  AttacheCaseGrey,
  AttacheCaseWhite,
  ContractGrey,
  ContractWhite,
  LoopGrey,
  LoopWhite,
  RandstadGrey,
  RandstadWhite,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { MenuSection } from 'src/Components/MenuSection/MenuSection.types';

export enum ADMIN_FIRST_SECTION {
  business = 'business',
  apps = 'apps',
  parameters = 'parameters',
}

export enum ADMIN_SECOND_SECTION {
  cgc = 'cgc',
  qualifications = 'qualifications',
  home = 'home',
  rights = 'rights',
}

export const menuItems: MenuSection<[ADMIN_FIRST_SECTION, ADMIN_SECOND_SECTION]>[] = [
  {
    title: 'référentiel métier',
    item: ADMIN_FIRST_SECTION.business,
    items: [
      {
        title: 'unité & CGC',
        item: ADMIN_SECOND_SECTION.cgc,
        iconSelected: <RandstadWhite />,
        iconNotSelected: <RandstadGrey />,
      },
      {
        title: 'qualifications',
        item: ADMIN_SECOND_SECTION.qualifications,
        iconSelected: <AttacheCaseWhite />,
        iconNotSelected: <AttacheCaseGrey />,
      },
    ],
  },
  {
    title: 'portail mobilité',
    item: ADMIN_FIRST_SECTION.apps,
    items: [
      {
        title: 'infos apps',
        item: ADMIN_SECOND_SECTION.home,
        iconSelected: <LoopWhite />,
        iconNotSelected: <LoopGrey />,
      },
    ],
  },
  {
    title: 'paramètres',
    item: ADMIN_FIRST_SECTION.parameters,
    items: [
      {
        title: 'gestion droits',
        item: ADMIN_SECOND_SECTION.rights,
        iconSelected: <ContractWhite />,
        iconNotSelected: <ContractGrey />,
      },
    ],
  },
];
