import React from 'react';

import {
  AddButton,
  BadgeDeprecated,
  DragDropList,
  HorizontalCardWithTitle,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { WhiteCirclePlus } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { DropResult } from 'react-beautiful-dnd';
import ContentLoader from 'react-content-loader';

import { EditionAppDetailsMode } from 'src/Containers/Modals/EditAppDetailsModal/EditAppDetailsModal.types';
import { useFetchAppDetails } from 'src/Hooks/HomeEdition/useFetchAppDetails';
import { useUpdateAppDetails } from 'src/Hooks/HomeEdition/useUpdateAppDetails';
import { useModalWithLocation } from 'src/Hooks/Navigation';
import { AppDetail, RandstadAppEnum } from 'src/Services/API';
import { appsConstants } from 'src/Utils/AppsDetailsValues/AppsDetailsValues';

import AppDetailsActions from './AppDetailsActions/AppDetailsActions.component';
import styles from './AppsDetailsEdition.module.scss';

const AppsDetailsEdition = () => {
  const { data: appDetails, isError, isLoading } = useFetchAppDetails();
  const editAppDetailsModal = useModalWithLocation('editAppDetails');
  const updateAppDetailsMutation = useUpdateAppDetails();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const minIndex = Math.min(result.source.index, result.destination.index);
    const maxIndex = Math.max(result.source.index, result.destination.index);
    const items: AppDetail[] = appDetails ?? [];

    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    const itemsToUpdate = items.slice(minIndex, maxIndex + 1);

    updateAppDetailsMutation.mutate({
      appDetails: itemsToUpdate.map((itemToUpdate: AppDetail, newPartialIndex) => ({
        ...itemToUpdate,
        order: minIndex + newPartialIndex,
      })),
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>liste des applications</div>
      {!isError && !isLoading && (
        <>
          <WithLightTitle title="retrouvez et classez par ordre d'apparition l'ensemble des applications présentes sur la partie droite de la page launcher">
            <DragDropList
              droppableId="appDetails"
              draggableItemClassname={styles.containerDrag}
              items={
                appDetails?.map(appDetail => ({
                  id: appDetail.app,
                  item: (
                    <HorizontalCardWithTitle
                      key={appDetail.app}
                      titleClassname={styles.appDetailsCardTitle}
                      subtitlesClassname={styles.appDetailsCardSubtitle}
                      title={appsConstants[appDetail.app]?.fullName ?? 'application non renseignée'}
                      subtitles={[appDetail.description ?? 'pas de description']}
                      draggable
                      rightActions={
                        <div className={styles.row}>
                          {appDetail.isHighlighted && (
                            <BadgeDeprecated className={styles.badge} value="en avant" />
                          )}
                          <AppDetailsActions appDetail={appDetail} />
                        </div>
                      }
                    />
                  ),
                })) ?? []
              }
              onDragEnd={onDragEnd}
            />
          </WithLightTitle>
          {Object.keys(RandstadAppEnum).filter(
            app => !appDetails?.map(appDetail => appDetail.app).includes(app as RandstadAppEnum)
          ).length > 0 && (
            <AddButton
              icon={<WhiteCirclePlus />}
              text="ajouter une application"
              onClick={() =>
                editAppDetailsModal.open({
                  modalParameters: { editionAppDetailsMode: EditionAppDetailsMode.CREATE },
                })
              }
            />
          )}
        </>
      )}
      {isLoading && (
        <ContentLoader height="12rem" width="100%" uniqueKey="AppDetailsLoader">
          <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="3rem" />
          <rect x="0" rx="0.5rem" ry="0.5rem" y="4rem" width="100%" height="3rem" />
          <rect x="0" rx="0.5rem" ry="0.5rem" y="8rem" width="100%" height="3rem" />
        </ContentLoader>
      )}
      {isError && <p>Erreur lors de la récupération des détails des applications</p>}
    </div>
  );
};

export default AppsDetailsEdition;
