import React, { useMemo, useState } from 'react';

import {
  CustomRandyIcon,
  WhiteRightArrow,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import MenuSection from 'src/Components/MenuSection';
import AdminParametersPopOverMenu from 'src/Containers/AdminPage/SideBar/AdminParametersPopOverMenu';
import history from 'src/Utils/history';

import { URL, menuItems } from '../CustomRandy.types';

import Styles from './SideBar.module.scss';

const SideBar = () => {
  const openedWidth = 196;
  const closedWidth = 64;

  const [opened, setOpened] = useState(true);

  const expand = useSpring({
    minWidth: opened ? `${openedWidth}px` : `${closedWidth}px`,
    maxWidth: opened ? `${openedWidth}px` : `${closedWidth}px`,
  });

  const spin = useSpring({
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  });

  const fade = useSpring({
    opacity: opened ? 1 : 0,
  });

  const location = useLocation();

  const activeItem = location.pathname.split('/').slice(2, 4) as [string, string];
  const setItem = useMemo(
    () => (item: [string, string]) => {
      history.push(`/${URL}/${item[0]}/${item[1]}`);
    },
    []
  );

  return (
    <animated.div style={expand}>
      <div className={Styles.SideBar}>
        <div className={Styles.Logo}>
          <CustomRandyIcon width={32} height={32} className={Styles.LogoImage} />
          <animated.div style={fade} className={Styles.MenuItemContainer}>
            <div className={Styles.VerticalSeparator} />
            <div className={Styles.AppTitle}>randy personnalisé</div>
          </animated.div>
        </div>
        {menuItems.map(menuSection => (
          <MenuSection
            key={menuSection.item}
            {...menuSection}
            opened={opened}
            activeItem={activeItem}
            setItem={setItem}
          />
        ))}
        <div className={Styles.BottomButtons}>
          <div className={Styles.MenuItemContainer}>
            <div className={Styles.TransparentBorder} />
            <div className={Styles.Disconnect}>
              <AdminParametersPopOverMenu />
            </div>
          </div>
        </div>
        <div className={Styles.RetractButton} onClick={() => setOpened(!opened)}>
          <animated.div style={spin} className={Styles.RetractIcon}>
            <WhiteRightArrow />
          </animated.div>
          <animated.div className={Styles.text} style={fade}>
            refermer
          </animated.div>
        </div>
      </div>
    </animated.div>
  );
};

export default SideBar;
