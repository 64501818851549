/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHistory, useLocation } from 'react-router-dom';

import {
  AddCGCToUnitsState,
  DeleteAppDetailsState,
  DeleteAppNewsState,
  DeleteRightState,
  EditAppDetailsState,
  EditAppNewsState,
  EditCGCState,
  EditRightState,
  LocationState,
} from 'src/Services/Routing';

export type ModalsParamList = {
  editRight: EditRightState;
  deleteRight: DeleteRightState;
  addCGCToUnits: AddCGCToUnitsState;
  editCGC: EditCGCState;
  editAppNews: EditAppNewsState;
  deleteAppNews: DeleteAppNewsState;
  editAppDetails: EditAppDetailsState;
  deleteAppDetails: DeleteAppDetailsState;
};

export type ModalName = keyof ModalsParamList;
export type ModalNameParamExtending<BaseState> = {
  [K in ModalName]: ModalsParamList[K] extends BaseState ? K : never;
}[ModalName];

export function useModal<T extends ModalName = ModalName>(modalName: T) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  return {
    open: (state: ModalsParamList[T]) => {
      history.push({
        pathname: pathname !== '/' ? `${pathname}/${modalName}` : modalName,
        state: state,
        search: search,
      });
    },
  };
}

export function useModalWithLocation<
  T extends ModalNameParamExtending<LocationState<any>> = ModalNameParamExtending<
    LocationState<any>
  >,
>(modalName: T) {
  const location = useLocation();
  const { open } = useModal(modalName);
  return {
    open: (state: Omit<ModalsParamList[T], 'background'>) => {
      open({ background: location, ...state });
    },
  };
}
