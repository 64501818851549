import React, { useCallback, useEffect, useState } from 'react';

import { TextInput } from '@randstad-lean-mobile-factory/react-components-core';
import { Copy } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import Styles from './CopyTextInput.module.scss';
import Props from './CopyTextInput.types';

const CopyTextInput = ({ value, copiedText, placeholder }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    setCopied(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleClick);
    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <div
      className={Styles.CopyTextInput}
      onClick={async () => {
        if (value) await navigator.clipboard.writeText(value).then(() => setCopied(true));
      }}
    >
      <TextInput
        value={value}
        disabled
        placeholder={placeholder}
        rightIcon={value ? <Copy /> : undefined}
        style={{
          pointerEvents: 'none',
        }}
        containerClassName={Styles.Input}
      />
      <div className={classnames(Styles.Tooltip, copied && Styles.Enabled)}>{copiedText}</div>
    </div>
  );
};

export default CopyTextInput;
