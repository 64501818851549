import React from 'react';

import {
  AddButton,
  DragDropList,
  HorizontalCardWithTitle,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { WhiteCirclePlus } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import { DropResult } from 'react-beautiful-dnd';
import ContentLoader from 'react-content-loader';

import { EditionAppNewsMode } from 'src/Containers/Modals/EditAppNewsModal/EditAppNewsModal.types';
import { useUpdateAppNewsOrder } from 'src/Hooks/HomeEdition/useUpdateAppNewsOrder';
import { useModalWithLocation } from 'src/Hooks/Navigation';
import { AppNews } from 'src/Services/API';
import { appsConstants } from 'src/Utils/AppsDetailsValues/AppsDetailsValues';

import AppNewsActions from './AppNewsActions/AppNewsActions.component';
import styles from './AppNewsList.module.scss';
import { Props } from './AppNewsList.types';

const AppNewsList = ({ isArchived, appNewsList, isError, isLoading }: Props) => {
  const editAppNewsModal = useModalWithLocation('editAppNews');
  const updateAppNewsOrderMutation = useUpdateAppNewsOrder();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const minIndex = Math.min(result.source.index, result.destination.index);
    const maxIndex = Math.max(result.source.index, result.destination.index);
    const items: AppNews[] = appNewsList ?? [];

    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    const itemsToUpdate = items.slice(minIndex, maxIndex + 1);

    updateAppNewsOrderMutation.mutate({
      isArchived,
      appNewsToUpdate: itemsToUpdate.map((itemToUpdate: AppNews, newPartialIndex) => ({
        id: itemToUpdate.id,
        order: minIndex + newPartialIndex,
      })),
    });
  };
  return (
    <div>
      <div className={styles.title}>
        {isArchived ? 'nouveautés archivées' : 'nouveautés sur les apps'}
      </div>
      {!isError && !isLoading && (
        <div>
          <WithLightTitle
            title={
              isArchived
                ? 'liste de vos paragraphes passés en "archivés"'
                : 'ajouter et classer les paragraphes sur les apps'
            }
          >
            <DragDropList
              droppableId="appNews"
              draggableItemClassname={styles.containerDrag}
              items={
                appNewsList?.map((oneAppNews, index) => ({
                  id: oneAppNews.id ?? index.toString(),
                  item: (
                    <HorizontalCardWithTitle
                      titleClassname={styles.appNewsCardTitle}
                      subtitlesClassname={styles.appNewsCardSubtitle}
                      title={`${
                        oneAppNews.app && appsConstants[oneAppNews.app]
                          ? appsConstants[oneAppNews.app]?.fullName
                          : 'application non renseignée'
                      } (v${oneAppNews.version ?? 'ersion non renseignée'}) - ajouté le ${moment(
                        oneAppNews.createdAt
                      ).format('L')} - modifié le ${moment(oneAppNews.updatedAt).format(
                        'L'
                      )} par ${oneAppNews.lastUpdateConsultant?.firstName} ${oneAppNews
                        .lastUpdateConsultant?.name}`}
                      subtitles={[
                        `${oneAppNews.newsTitle}${
                          oneAppNews.description ? ` : ${oneAppNews.description}` : ''
                        }`,
                      ]}
                      draggable
                      rightActions={<AppNewsActions isArchived={isArchived} appNews={oneAppNews} />}
                    />
                  ),
                })) ?? []
              }
              onDragEnd={onDragEnd}
            />
          </WithLightTitle>
          {!isArchived && (
            <AddButton
              icon={<WhiteCirclePlus />}
              text="ajouter un paragraphe"
              onClick={() =>
                editAppNewsModal.open({
                  modalParameters: { editionAppNewsMode: EditionAppNewsMode.CREATE, isArchived },
                })
              }
            />
          )}
        </div>
      )}
      {isLoading && (
        <ContentLoader height="12rem" width="100%" uniqueKey="AppNewsLoader">
          <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="3rem" />
          <rect x="0" rx="0.5rem" ry="0.5rem" y="4rem" width="100%" height="3rem" />
          <rect x="0" rx="0.5rem" ry="0.5rem" y="8rem" width="100%" height="3rem" />
        </ContentLoader>
      )}
      {isError && <p>Erreur lors de la récupération des nouveautés des applications</p>}
    </div>
  );
};

export default AppNewsList;
