import React, { useState } from 'react';

import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Folder,
  Pencil,
  ThreeDots,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';

import { EditionAppNewsMode } from 'src/Containers/Modals/EditAppNewsModal/EditAppNewsModal.types';
import { useMoveAppNewsToArchivedOrNot } from 'src/Hooks/HomeEdition/useMoveAppNewsToArchivedOrNot';
import { useModalWithLocation } from 'src/Hooks/Navigation';

import styles from './AppNewsActions.module.scss';
import { Props } from './AppNewsActions.types';

const AppNewsActions = ({ isArchived, appNews }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const editAppNewsModal = useModalWithLocation('editAppNews');
  const deleteAppNewsModal = useModalWithLocation('deleteAppNews');
  const moveAppNewsToArchivedOrNotMutation = useMoveAppNewsToArchivedOrNot();
  return (
    <PopupMenu
      position={'left top'}
      width={250}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpen })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        key="editAppNews"
        icon={<Pencil />}
        text="modifier"
        onClick={() => {
          editAppNewsModal.open({
            modalParameters: { editionAppNewsMode: EditionAppNewsMode.MODIFY, isArchived, appNews },
          });
        }}
      />
      <PopupMenu.Item
        key="archiveAppNews"
        icon={<Folder />}
        text={isArchived ? 'désarchiver' : 'archiver'}
        onClick={() => {
          moveAppNewsToArchivedOrNotMutation.mutate({
            toArchived: !isArchived,
            appNewsId: appNews.id ?? '',
          });
        }}
      />
      <PopupMenu.Item
        key="deleteAppNews"
        icon={<Trashcan />}
        text="supprimer"
        onClick={() => {
          deleteAppNewsModal.open({
            modalParameters: { isArchived, id: appNews.id ?? '' },
          });
        }}
      />
    </PopupMenu>
  );
};

export default AppNewsActions;
