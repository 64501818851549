import React, { useState } from 'react';

import {
  Button,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { PencilAndLine } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import ContentLoader from 'react-content-loader';

import { useModalWithLocation } from 'src/Hooks/Navigation';

import styles from './CGCs.module.scss';
import { Props } from './CGCs.types';

const CGCs = ({ cgcs, isLoading, isError }: Props) => {
  const editCGCModal = useModalWithLocation('editCGC');
  const [cgcsKeyword, setCGCsKeyword] = useState<string>('');
  return (
    <>
      <WithLightTitle title="trouvez des CGC et leurs unités rattachées">
        <TextInput
          value={cgcsKeyword}
          placeholder="ex : CGC Angers"
          onChange={event => setCGCsKeyword((event.target as HTMLInputElement).value)}
        />
      </WithLightTitle>
      <div className={styles.CGCListContainer}>
        {cgcs
          .filter(cgc => cgc.label.includes(cgcsKeyword.toUpperCase().trim()))
          .map(cgc => (
            <div key={cgc.id}>
              <div className={styles.separator} />
              <div className={styles.CGCCard}>
                <div>
                  <div className={styles.CGCName}>{cgc.label}</div>
                  <div className={styles.CGCUnits}>
                    {cgc.units.length > 0
                      ? `unités : ${cgc.units.map(unit => unit.id).join(', ')}`
                      : 'aucune unité rattachée'}
                  </div>
                </div>
                <div>
                  <Button.Tertiary className={styles.CGCEditButton}>
                    <PencilAndLine
                      onClick={() =>
                        editCGCModal.open({
                          modalParameters: { cgcWithUnits: cgc },
                        })
                      }
                    />
                  </Button.Tertiary>
                </div>
              </div>
            </div>
          ))}
        {isLoading && (
          <ContentLoader height="12rem" width="100%" uniqueKey="CGCsLoader">
            <rect x="0" rx="0.5rem" ry="0.5rem" y="0" width="100%" height="3rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="4rem" width="100%" height="3rem" />
            <rect x="0" rx="0.5rem" ry="0.5rem" y="8rem" width="100%" height="3rem" />
          </ContentLoader>
        )}
        {isError && <p>Erreur lors de la récupération des CGC</p>}
      </div>
    </>
  );
};
export default CGCs;
